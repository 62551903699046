import { styled, Drawer as MuiDrawer, Typography } from '@mui/material';

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    width: 640px;

    ${({ theme }) =>
      `${theme.breakpoints.down('md')} {
        width: 100%;
      }`}
  }

  ${({ theme }) => ({
    '& > .MuiPaper-root': {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(1.5)}`,
    },
  })}
`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.spacing(2.5)} ${theme.spacing(1)} ${theme.spacing(1)}`};
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 2000;
`;

export const PanelTitle = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(5)};
  font-weight: 600;

  ${({ theme }) =>
    `${theme.breakpoints.down('md')} {
        margin-right: 0;
      }`}
`;

PanelTitle.defaultProps = {
  variant: 'h2',
};

export const HeaderPart = styled('div')`
  display: flex;
  align-items: center;
`;

export const Content = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-top: 0;
`;
