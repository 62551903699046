import { StoreType } from './storeConfig.types';

/**
 * Useful enum that contains some of the most used digital streaming platforms (DSP)
 */
export enum DSP {
  APPLE_MUSIC = 'appleMusic',
  DEEZER = 'deezer',
  SPOTIFY = 'spotify',
}

export const StreamingPlatform = {
  type: StoreType.Stream,
};
export const PlatformInfos = {
  [DSP.APPLE_MUSIC]: {
    name: DSP.APPLE_MUSIC,
    displayName: 'Apple Music',
    baseUrlPlaylist: 'https://music.apple.com/playlist/',
    type: StoreType.Stream,
  },
  [DSP.DEEZER]: {
    name: DSP.DEEZER,
    displayName: 'Deezer',
    baseUrlPlaylist: 'https://deezer.com/playlist/',
    type: StoreType.Stream,
  },
  [DSP.SPOTIFY]: {
    name: DSP.SPOTIFY,
    displayName: 'Spotify',
    baseUrlPlaylist: 'https://spotify.com/playlist/',
    type: StoreType.Stream,
  },
};
