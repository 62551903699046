import React from 'react';

import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import RemoveIcon from '@mui/icons-material/RemoveCircle';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import FieldSet from 'shared/components/FieldSet';
import Highlight from 'shared/components/Highlight';
import ImageUploader from 'shared/components/ImageUploader';
import PlatformUrlField from 'shared/components/PlatformUrlField';
import StoreSelector from 'shared/components/StoreSelector';
import { authUserIsCustomer } from 'shared/entities/auth/auth.types';
import { TargetType } from 'shared/entities/heartbeat/heartbeat.types';
import {
  PlatformInfos,
  DSP,
} from 'shared/entities/storeConfig/streamingPlatform.types';
import { useAppFeatureFlag } from 'shared/hooks/useAppFeatureFlag';
import { useImageUpload } from 'shared/hooks/useImageUpload';
import i18n from 'shared/i18n';
import { useAppContext, useAppWideData } from 'shared/state';
import { isDefinedAndNotNull } from 'shared/utils/typeguards';

import * as Styled from './styled';

const commonTextFieldProps: Partial<TextFieldProps> = {
  variant: 'outlined',
  fullWidth: true,
  InputLabelProps: { shrink: true },
};

const getSocialHandleValidationRule = () =>
  yup
    .string()
    .test(
      'is-profile-handle',
      i18n.t(
        'ui.component.artist_profile.form.field.social_handle.validation.format.message',
        'Please enter your profile name without "@" nor the rest of the URL.',
      ),
      (val) => !val?.includes('@') && !val?.includes('/'),
    );

const PROFILE_URLS_DSP_PLATFORMS = [DSP.SPOTIFY, DSP.DEEZER];

export const getArtistProfileSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required(
        i18n.t(
          'ui.component.artist_profile.form.field.name.validation.required.message',
          'The artist name is required.',
        ),
      ),
    socialHandles: yup.object().shape({
      instagramHandle: getSocialHandleValidationRule(),
      facebookHandle: getSocialHandleValidationRule(),
      twitterHandle: getSocialHandleValidationRule(),
      tiktokHandle: getSocialHandleValidationRule(),
      VK: getSocialHandleValidationRule(),
      youtubeChannelName: getSocialHandleValidationRule(),
    }),
    storeProfileLinks: yup.array(
      yup.object().shape({
        storeName: yup.string().required(),
        url: yup
          .string()
          .url(
            i18n.t(
              'ui.component.artist_profile.form.field.store_profile_link.url.validation.url.message',
              'The profile link must be a valid URL',
            ),
          )
          .required(
            i18n.t(
              'ui.component.artist_profile.form.field.store_profile_link.url.validation.required.message',
              'The profile link is required',
            ),
          ),
        id: yup.string().when('storeName', {
          is: (storeName) => PROFILE_URLS_DSP_PLATFORMS.includes(storeName),
          then: yup
            .string()
            .required(
              i18n.t(
                'ui.component.artist_profile.form.field.store_profile_link.id.validation.required.message',
                'The link must be a valid profile URL',
              ),
            ),
          otherwise: yup.string().nullable(), // 'nullable' because backend has assigned id: null to existing profileLinks
        }),
      }),
    ),
    storeOfficialPlaylists: yup.array(
      yup.object().shape({
        storeName: yup.string().required(),
        url: yup
          .string()
          .url(
            i18n.t(
              'ui.component.artist_profile.form.field.official_playlists.url.validation.url.message',
              'The official playlist link must be a valid URL',
            ),
          )
          .required(
            i18n.t(
              'ui.component.artist_profile.form.field.official_playlists.id.validation.required.message',
              'The link must be a valid official playlist URL',
            ),
          ),
        id: yup.string().when('storeName', {
          is: (storeName) =>
            OFFICIAL_PLAYLIST_DSP_PLATFORMS.includes(storeName),
          then: yup
            .string()
            .required(
              i18n.t(
                'ui.component.artist_profile.form.field.official_playlists.id.validation.required.message',
                'The link must be a valid official playlist URL',
              ),
            ),
          otherwise: yup.string().nullable(),
        }),
      }),
    ),
    subDomain: yup
      .string()
      .matches(
        /^(?:(?![()@:%_+.~#?&//=!$&*,;'[\]\s]).)*$/u,
        i18n.t(
          'ui.component.artist_profile.form.field.subdomain.validation.format.message',
          'This is not a valid subdomain.',
        ),
      ),
  });

export type FormValues = {
  profilePictureId: string;
  name: string;
  socialHandles: {
    instagramHandle: string;
    facebookHandle: string;
    twitterHandle: string;
    tiktokHandle: string;
    VK: string;
    youtubeChannelName: string;
  };
  storeProfileLinks: Array<{
    storeName: string;
    url: string;
    id?: string;
  }>;
  storeOfficialPlaylists?: Array<{
    storeName: string;
    url: string;
    id?: string;
  }>;
  subDomain: string;
};

export const HIGHLIGHT_DOM_IDS = {
  ARTIST_URLS: 'artist-urls',
  ARTIST_SUBDOMAIN: 'artist-subdomain',
};

/****************************************************************************/
// ARTIST PROFILE SETTINGS TAB
/****************************************************************************/
const Profile = () => {
  const { t } = useTranslation();

  const {
    state: { user },
  } = useAppContext();
  const isCustomer = authUserIsCustomer(user);

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isOfficialPlaylistEnabled = useAppFeatureFlag(
    'artist_official_playlist_enabled',
  );

  return (
    <Styled.Container>
      <ProfilePictureEditor />
      <Grid item xs={12} sm={8}>
        <Box marginBottom={2}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                label={t(
                  'ui.component.artist_profile.form.field.name.label',
                  'Artist name',
                )}
                error={Boolean(errors.name)}
                helperText={errors.name?.message as any}
                required
                {...commonTextFieldProps}
                {...field}
              />
            )}
          />
        </Box>
        <Box marginBottom={1}>
          <Divider />
        </Box>

        <Highlight id={HIGHLIGHT_DOM_IDS.ARTIST_URLS}>
          <ProfileURLs />
        </Highlight>
        {isOfficialPlaylistEnabled && <OfficialPlaylists />}
        <Highlight id={HIGHLIGHT_DOM_IDS.ARTIST_SUBDOMAIN}>
          <FieldSet
            title={t(
              'ui.component.artist_profile.form.fieldset.subdomain.title',
              'Artist subdomain',
            )}
            description={t(
              'ui.component.artist_profile.form.fieldset.subdomain.description',
              'Customize your links URL with a subdomain:',
            )}
            variant="condensed"
          >
            <Controller
              name="subDomain"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t(
                    'ui.component.artist_profile.form.field.subdomain.label',
                    'Subdomain',
                  )}
                  error={Boolean(errors.subDomain)}
                  helperText={errors.subDomain?.message as any}
                  {...commonTextFieldProps}
                  {...field}
                />
              )}
            />
          </FieldSet>
        </Highlight>
        {!isCustomer && <SocialHandles />}
      </Grid>
    </Styled.Container>
  );
};

export default Profile;

/****************************************************************************/
// ARTIST PROFILE PICTURE EDITOR
/****************************************************************************/
const ProfilePictureEditor = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.artist_profile.profile_picture_editor',
  });

  const { getValues, setValue } = useFormContext();

  const { error } = useImageUpload(handleChangeImage);

  const imageId = getValues('profilePictureId');
  const hasError = isDefinedAndNotNull(error);

  // On Change handler for the image uploader (to set the form value manually)
  function handleChangeImage(id: string) {
    /**
     * shouldValidate is set to true to trigger a rerender, otherwise
     * the thumbnail is not updated when clearing a picture (id = empty)
     */
    setValue('profilePictureId', id, { shouldValidate: true });
  }

  return (
    <Styled.LeftColumn>
      <Box marginBottom={1}>
        <Styled.ProfilePictureLabel error={hasError}>
          {t('title', 'Profile picture')}
        </Styled.ProfilePictureLabel>
        {hasError && error && (
          <FormHelperText error>{error.message}</FormHelperText>
        )}
      </Box>
      <ImageUploader
        changeHandler={handleChangeImage}
        value={{ id: imageId }}
        alt={t('alt_title', 'Artist profile picture')}
        isRound
      />
    </Styled.LeftColumn>
  );
};

/****************************************************************************/
// PROFILES URLS (one per store)
/****************************************************************************/
const ProfileURLs = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.artist_profile.store_profile_links',
  });

  const { storesConfigs } = useAppWideData();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const {
    fields: profileLinksFields,
    append: addProfileLink,
    remove: removeProfileLink,
  } = useFieldArray({
    name: 'storeProfileLinks',
    control,
  });

  const addedStores = getValues('storeProfileLinks').map(
    (storeLink) => storeLink.storeName,
  );
  const availableStoresConfigs = Object.values(storesConfigs ?? {}).filter(
    (store) => !addedStores.includes(store.name),
  );

  return (
    <FieldSet
      title={t('title', 'Artist URLs')}
      variant="condensed"
      description={t(
        'description',
        'Redirect fans to the Artist Profile when a release URL is not available or when they click on a "Follow" store.',
      )}
    >
      <StoreSelector
        availableStoresConfigs={availableStoresConfigs}
        onChange={({ name }) =>
          addProfileLink({ storeName: name, url: '', id: '' })
        }
      />
      {profileLinksFields.map((field, index) => {
        const baseFieldPath = `storeProfileLinks.${index}`;
        const urlFieldName = `${baseFieldPath}.url`;
        const idFieldName = `${baseFieldPath}.id`;
        const storeName = getValues(`${baseFieldPath}.storeName`);
        const storeLabel =
          storeName && storesConfigs
            ? Object.values(storesConfigs).find(
                (store) => store.name === storeName,
              )?.displayName
            : storeName || field.id;
        const urlDefaultValue = getValues(urlFieldName);
        const idDefaultValue = getValues(idFieldName);
        const profileUrlError =
          errors.storeProfileLinks &&
          errors.storeProfileLinks[index] &&
          errors.storeProfileLinks[index].url;
        return (
          <div key={storeName} css={{ position: 'relative' }}>
            <Tooltip
              title={t(
                'remove_profile_link',
                'Remove {{storeLabel}} profile link',
                { storeLabel },
              )}
            >
              <IconButton
                onClick={() => removeProfileLink(index)}
                aria-label={t('remove_profile_link', { storeLabel })}
                color="secondary"
                css={{
                  position: 'absolute',
                  top: '3px',
                  left: '-40px',
                }}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
            {PROFILE_URLS_DSP_PLATFORMS.includes(storeName) ? (
              <Controller
                name={urlFieldName}
                control={control}
                defaultValue={urlDefaultValue}
                render={({ field }) => {
                  const profileIdError =
                    errors.storeProfileLinks &&
                    errors.storeProfileLinks[index] &&
                    errors.storeProfileLinks[index].id;
                  return (
                    <PlatformUrlField
                      inputProps={{ ...field }}
                      inputId={`${storeName}-profile-url`}
                      customLabel={storeLabel}
                      resourceId={idDefaultValue}
                      resourceUrl={urlDefaultValue}
                      onChange={(value) =>
                        setValue(baseFieldPath, {
                          storeName,
                          url: value,
                          id: undefined,
                        })
                      }
                      onUrlResolved={({ resourceId }) => {
                        setValue(idFieldName, resourceId, {
                          shouldValidate: true,
                        });
                      }}
                      onUrlNotResolved={() => {
                        setValue(idFieldName, undefined, {
                          shouldValidate: true,
                        });
                      }}
                      platformName={storeName as DSP}
                      targetType={TargetType.ARTIST}
                      additionnalError={
                        profileIdError?.message || profileUrlError?.message
                      }
                    />
                  );
                }}
              />
            ) : (
              <Controller
                name={urlFieldName}
                control={control}
                defaultValue={urlDefaultValue}
                render={({ field }) => (
                  <TextField
                    id={`${storeName}-profile-url`}
                    label={storeLabel}
                    error={Boolean(profileUrlError)}
                    helperText={profileUrlError?.message}
                    {...commonTextFieldProps}
                    {...field}
                  />
                )}
              />
            )}
          </div>
        );
      })}
    </FieldSet>
  );
};

/****************************************************************************/
// AMD DASHBOARD FIELDS - Social media handles (only for employees)
/****************************************************************************/
const SocialHandles = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  return (
    <FieldSet
      title={t(
        'ui.component.artist_profile.form.fieldset.social_handles.title',
        'Artist social handles for AMD and AAA dashboards (Artist Services)',
      )}
      variant="condensed"
    >
      <Controller
        name="socialHandles.instagramHandle"
        control={control}
        render={({ field }) => (
          <TextField
            id="instagram-handle"
            label="Instagram"
            error={Boolean(errors.socialHandles?.instagramHandle)}
            helperText={errors.socialHandles?.instagramHandle?.message}
            {...commonTextFieldProps}
            {...field}
          />
        )}
      />
      <Controller
        name="socialHandles.facebookHandle"
        control={control}
        render={({ field }) => (
          <TextField
            id="facebook-handle"
            label="Facebook"
            error={Boolean(errors.socialHandles?.facebookHandle)}
            helperText={errors.socialHandles?.facebookHandle?.message}
            {...commonTextFieldProps}
            {...field}
          />
        )}
      />
      <Controller
        name="socialHandles.twitterHandle"
        control={control}
        render={({ field }) => (
          <TextField
            id="twitter-handle"
            label="Twitter"
            error={Boolean(errors.socialHandles?.twitterHandle)}
            helperText={errors.socialHandles?.twitterHandle?.message}
            {...commonTextFieldProps}
            {...field}
          />
        )}
      />
      <Controller
        name="socialHandles.tiktokHandle"
        control={control}
        render={({ field }) => (
          <TextField
            id="tiktok-handle"
            label="TikTok"
            error={Boolean(errors.socialHandles?.tiktokHandle)}
            helperText={errors.socialHandles?.tiktokHandle?.message}
            {...commonTextFieldProps}
            {...field}
          />
        )}
      />
      <Controller
        name="socialHandles.VK"
        control={control}
        render={({ field }) => (
          <TextField
            id="vk-handle"
            label="VK"
            error={Boolean(errors.socialHandles?.VK)}
            helperText={errors.socialHandles?.VK?.message}
            {...commonTextFieldProps}
            {...field}
          />
        )}
      />
      <Controller
        name="socialHandles.youtubeChannelName"
        control={control}
        render={({ field }) => (
          <TextField
            id="youtube-handle"
            label="YouTube"
            error={Boolean(errors.socialHandles?.youtubeChannelName)}
            helperText={errors.socialHandles?.youtubeChannelName?.message}
            {...commonTextFieldProps}
            {...field}
          />
        )}
      />
    </FieldSet>
  );
};

//#region Official Playlists fields
const OFFICIAL_PLAYLIST_DSP_PLATFORMS = [
  PlatformInfos[DSP.APPLE_MUSIC],
  PlatformInfos[DSP.SPOTIFY],
  PlatformInfos[DSP.DEEZER],
];

const OfficialPlaylists = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.artist_profile.form.fieldset.official_playlists',
  });
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  const {
    fields: officialPlaylistFields,
    append: addOfficialPlaylist,
    remove: removeOfficialPlaylist,
  } = useFieldArray({
    name: 'storeOfficialPlaylists',
    control,
  });

  const addedOfficialPlaylistNames = (
    getValues('storeOfficialPlaylists') || []
  ).reduce(
    (acc, officialPlaylist) =>
      officialPlaylist.url ? [...acc, officialPlaylist.storeName] : acc,
    [],
  );

  const availableStoresConfigs = OFFICIAL_PLAYLIST_DSP_PLATFORMS.filter(
    (store) => !addedOfficialPlaylistNames.includes(store.name),
  );
  const shouldDisplayStorSelector = availableStoresConfigs.length !== 0;

  return (
    <FieldSet
      title={t('title', 'Official Playlists')}
      variant="condensed"
      description={t(
        'description',
        'Fill in your official artist playlist URLs so that the Fan Automation « Follow Official Playlist" can be pre-filled.',
      )}
    >
      {shouldDisplayStorSelector && (
        <StoreSelector
          availableStoresConfigs={availableStoresConfigs}
          onChange={({ name }) => {
            addOfficialPlaylist({
              storeName: name,
              id: name,
              url: PlatformInfos[name].baseUrlPlaylist,
            });
          }}
        />
      )}
      {officialPlaylistFields.map((field, index) => {
        const baseFieldPath = `storeOfficialPlaylists.${index}`;
        const urlFieldName = `${baseFieldPath}.url`;
        const idFieldName = `${baseFieldPath}.id`;
        const storeName = getValues(`${baseFieldPath}.storeName`);
        const storeLabel = storeName
          ? Object.values(OFFICIAL_PLAYLIST_DSP_PLATFORMS).find(
              (store) => store.name === storeName,
            )?.displayName
          : storeName || field.id;
        const urlDefaultValue = getValues(urlFieldName);
        const idDefaultValue = getValues(idFieldName);
        const profileUrlError =
          errors.storeProfileLinks &&
          errors.storeProfileLinks[index] &&
          errors.storeProfileLinks[index].url;
        return (
          <div key={storeName} css={{ position: 'relative' }}>
            <Tooltip
              title={t('remove', 'Remove {{storeLabel}} official playlist', {
                storeLabel,
              })}
            >
              <IconButton
                onClick={() => removeOfficialPlaylist(index)}
                aria-label={t(
                  'remove',
                  'Remove {{storeLabel}} official playlist',
                  { storeLabel },
                )}
                color="secondary"
                css={{
                  position: 'absolute',
                  top: '3px',
                  left: '-40px',
                }}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
            <Controller
              name={urlFieldName}
              control={control}
              defaultValue={urlDefaultValue}
              render={({ field }) => {
                const profileIdError =
                  errors.storeProfileLinks &&
                  errors.storeProfileLinks[index] &&
                  errors.storeProfileLinks[index].id;
                return (
                  <PlatformUrlField
                    key={`${field.ref}`}
                    inputProps={{ ...field }}
                    inputId={`${storeName}-playlist-url`}
                    customLabel={storeLabel}
                    resourceId={idDefaultValue}
                    resourceUrl={urlDefaultValue}
                    onChange={(value) =>
                      setValue(baseFieldPath, {
                        storeName,
                        url: value,
                        id: storeName,
                      })
                    }
                    onUrlResolved={({ resourceId }) => {
                      setValue(idFieldName, resourceId, {
                        shouldValidate: true,
                      });
                    }}
                    onUrlNotResolved={() => {
                      setValue(idFieldName, undefined, {
                        shouldValidate: true,
                      });
                    }}
                    platformName={storeName as DSP}
                    targetType={TargetType.PLAYLIST}
                    additionnalError={
                      profileIdError?.message || profileUrlError?.message
                    }
                  />
                );
              }}
            />
          </div>
        );
      })}
    </FieldSet>
  );
};
//#endregion
