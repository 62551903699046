import { AxiosResponse } from 'axios';

import { Action } from '../action/action.types';
import { api } from '../api';
import { SlugValidationInfosResponse } from '../backlinkSettings/backlinkSettings.api.types';
import { transformBacklinkSettingsDataV1ToV2 } from '../backlinkSettings/mocks/fixtures/backlinkSettings.fixture.helpers';
import {
  FetchBacklinksPayload,
  CreateBacklinkPayload,
  UpdateBacklinkPayload,
  FetchBacklinkActionPayload,
  CreateBacklinkActionPayload,
  UpdateBacklinkActionPayload,
  FetchAdminBacklinksPayload,
  ExportAdminBacklinksPayload,
  ExportAdminBacklinksResponse,
  ExportAdminBacklinksStatusPayload,
  FetchBacklinksPerformancePayload,
  BacklinksPerformanceResponse,
  ValidateSlugPayload,
} from './backlink.api.types';
import { Backlink, BacklinkWithDspActionsCount } from './backlink.types';
import backlink from './mocks/fixtures/backlink.fixture.one';

export const backlinkBaseApiPath = '/backlinks';

export const createBacklink = async (
  payload: CreateBacklinkPayload,
): Promise<Backlink> => {
  const response = await api.post<Backlink>(backlinkBaseApiPath, payload);
  return response.data;
};

export const updateBacklink = async ({
  backlinkId,
  settingsData,
}: UpdateBacklinkPayload): Promise<Backlink | AxiosResponse> => {
  const response = await api.patch<Backlink>(
    `${backlinkBaseApiPath}/${backlinkId}`,
    {
      backlinkId,
      settingsData,
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return response;
};

export const updateBacklinkV2 = async ({
  backlinkId,
  settingsDataV2,
}: Omit<UpdateBacklinkPayload, 'settingsData'>): Promise<
  Backlink | AxiosResponse
> => {
  const response = await api.patch<Backlink>(
    `${backlinkBaseApiPath}/${backlinkId}`,
    {
      backlinkId,
      settingsDataV2,
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return response;
};

export const fetchBacklinks = async (
  requestPayload: FetchBacklinksPayload = {
    page: 0,
  },
): Promise<{
  backlinks: Backlink[];
  totalCount: number;
}> => {
  const results = await api.get<{
    backlinks: Backlink[];
    totalCount: number;
  }>(backlinkBaseApiPath, {
    params: requestPayload,
  });

  return results.data;
};

export const fetchBacklink = async (backlinkId: string): Promise<Backlink> => {
  const results = await api.get<Backlink>(
    `${backlinkBaseApiPath}/${backlinkId}`,
  );
  return results.data;
};

export const fetchBacklinkV2 = async (
  backlinkId: string,
): Promise<Backlink> => {
  /**
   * mocks for backlink
   * used while working b4 the backend integration is done
   *
   * We need different artist and release Ids for staging and review environments
   * so that the api calls made with those ids do not fail and can access existing entities on those environments
   *
   * TODO: delete when back is done
   */

  // both envs are considered 'staging'
  const isStaging = new RegExp('ams-sta').test(window.location.host);
  // but the review env is different
  const isReview = new RegExp('rvw-').test(window.location.host);

  const artistId = isReview
    ? '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'
    : 'e6ded64a-b883-4540-a914-7e88c5af4eb3';

  const releaseId = isReview
    ? 'f28bee5a-2100-4412-a6ab-bf3b2e874ad7'
    : '6ec0b3ad-ba01-4998-8104-90b23609d735';

  if (isStaging) {
    return {
      ...backlink,
      artist: backlink.artist && {
        ...backlink.artist,
        id: artistId,
      },
      releaseId,
      settingsDataV2: transformBacklinkSettingsDataV1ToV2(
        backlink.settingsData,
      ),
    };
  }

  const results = await api.get<Backlink>(
    `${backlinkBaseApiPath}/v2/${backlinkId}`,
  );
  return results.data;
};

export const deleteBacklink = async (
  backlinkId: string,
): Promise<AxiosResponse> => {
  const results = await api.delete<number>(
    `${backlinkBaseApiPath}/${backlinkId}`,
  );
  return results;
};

export const validateSlug = async (
  params: ValidateSlugPayload,
): Promise<SlugValidationInfosResponse> => {
  const results = await api.get(`${backlinkBaseApiPath}/slugIsAvailable`, {
    params,
  });
  return results.data;
};

export const fetchShareExtensions = async (): Promise<any[]> => {
  const results = await api.get(`${backlinkBaseApiPath}/shareExtensions`);
  return results.data;
};

export const fetchBacklinkAction = async ({
  backlinkId,
  actionId,
}: FetchBacklinkActionPayload): Promise<Action> => {
  const results = await api.get(
    `${backlinkBaseApiPath}/${backlinkId}/actions/${actionId}`,
  );
  return results.data;
};

export const createBacklinkAction = async ({
  backlinkId,
  action,
}: CreateBacklinkActionPayload): Promise<Action> => {
  const results = await api.post(
    `${backlinkBaseApiPath}/${backlinkId}/actions`,
    action,
  );
  return results.data;
};

export const updateBacklinkAction = async ({
  backlinkId,
  actionId,
  action,
}: UpdateBacklinkActionPayload): Promise<Action> => {
  const results = await api.patch(
    `${backlinkBaseApiPath}/${backlinkId}/actions/${actionId}`,
    action,
  );
  return results.data;
};

/**
 * Synchronize backlink from database
 */
export const refreshBacklink = async ({
  backlinkId,
}: {
  backlinkId: string;
}): Promise<Backlink | undefined> => {
  if (!backlinkId) return;
  const results = await api.put(
    `${backlinkBaseApiPath}/${backlinkId}/release/metadata/refresh`,
  );
  return results.data;
};

export const fetchAdminBacklinks = async (
  requestPayload: FetchAdminBacklinksPayload,
): Promise<{
  backlinks: BacklinkWithDspActionsCount[];
  totalCount: number;
}> => {
  const results = await api.get<{
    backlinks: any[];
    totalCount: number;
  }>(`${backlinkBaseApiPath}/admin`, {
    params: requestPayload,
  });
  return results.data;
};

/**
 * Backlinks Export (Admin)
 *
 * it is comprised of 2 routes:
 *
 * one to trigger the export job & get the download url
 * the other one to poll the export job status
 *
 * when the status route indicates the job has COMPLETED
 * the main route must be called again to get the download url
 */

/**
 * POST
 * will trigger a job to export all backlinks filtered by the request payload
 */
export const exportAdminBacklinks = async (
  requestPayload: ExportAdminBacklinksPayload,
): Promise<ExportAdminBacklinksResponse> => {
  const response = await api.post('/exports/admin/backlinks', requestPayload);
  return response.data;
};

/**
 * GET status
 * will get the status of a given export job
 */
export const exportAdminBacklinksStatus = async (
  params: ExportAdminBacklinksStatusPayload,
): Promise<ExportAdminBacklinksResponse> => {
  const response = await api.get('/exports/admin/backlinks/status', {
    params,
  });
  return response.data;
};

export const fetchBacklinksPerformance = async (
  requestPayload: FetchBacklinksPerformancePayload,
): Promise<BacklinksPerformanceResponse> => {
  const results = await api.get(`${backlinkBaseApiPath}/performance`, {
    params: requestPayload,
  });
  return results.data;
};
