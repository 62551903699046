import React from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { useQueryClient } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import * as backlinkApi from 'shared/entities/backlink/backlink.api';
import {
  BacklinksPerformanceResponse,
  ExportAdminBacklinksResponse,
  FetchAdminBacklinksPayload,
  FetchBacklinksPayload,
  FetchBacklinksPerformancePayload,
  PaginatedBacklinksResponse,
} from 'shared/entities/backlink/backlink.api.types';
import { Backlink } from 'shared/entities/backlink/backlink.types';
import {
  ExportDataStatusEnum,
  POLLING_DEFAULT_INTERVAL,
  POLLING_LONG_INTERVAL,
} from 'shared/entities/common.constants';
import { AppQueryAndMutationMeta } from 'shared/hooks/useAppQueryClient';
import { useNotification } from 'shared/hooks/useNotification';
import { downloadFileAtUrl } from 'shared/utils/file';

/**
 * Fetch the paginated backlinks and cache them individually for future use
 * @param params
 */
export function useFetchBacklinks(
  params?: FetchBacklinksPayload,
  options?: UseQueryOptions<
    PaginatedBacklinksResponse,
    unknown,
    PaginatedBacklinksResponse,
    [string, FetchBacklinksPayload | undefined]
  >,
) {
  const queryClient = useQueryClient();

  const { data: paginatedResults, ...rest } = useQuery(
    ['backlinks', params],
    () => backlinkApi.fetchBacklinks(params),
    {
      meta: {
        messages: {
          error: 'Error while fetching backlinks',
        },
      } as AppQueryAndMutationMeta,
      onSuccess: (data: PaginatedBacklinksResponse) => {
        data.backlinks.forEach((backlink: Backlink) => {
          queryClient.setQueryData(['backlink', backlink.id], backlink);
        });

        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
      ...options,
    },
  );

  const invalidateQuery = () =>
    queryClient.invalidateQueries(['backlinks', params]);

  return { paginatedResults, invalidateQuery, ...rest };
}

/**
 * Fetch one backlink
 * @param id
 */
export function useFetchBacklink(id: string) {
  const queryClient = useQueryClient();

  const queryKey = ['backlink', id];

  const { data: backlink, ...rest } = useQuery(queryKey, () =>
    backlinkApi.fetchBacklink(id),
  );

  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);

  return { backlink, invalidateQuery, ...rest };
}

/**
 * Fetch a specific backlink given @id
 * return the version 2 of the backlinkSettings format
 */
export function useFetchBacklinkV2(id: string) {
  const queryClient = useQueryClient();

  const queryKey = ['backlinkV2', id];

  const { data: backlink, ...rest } = useQuery(queryKey, () =>
    backlinkApi.fetchBacklinkV2(id),
  );

  const invalidateQuery = () => queryClient.invalidateQueries([queryKey, id]);

  return { backlink, invalidateQuery, ...rest };
}

/**
 * Create Backlink Mutation Hook
 * wraps a react-query {@link useMutation}
 */
export function useCreateBacklink(options?: {
  shouldNotifyOnsuccess: boolean;
}) {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });
  const { notify } = useNotification();
  const queryClient = useQueryClient();

  const { shouldNotifyOnsuccess } = options || { shouldNotifyOnsuccess: true };

  const { mutate: createBacklink, ...rest } = useMutation(
    backlinkApi.createBacklink,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['backlinks']);
        if (shouldNotifyOnsuccess)
          notify({
            type: AppNotificationTypes.Success,
            message: t('link_created', 'Link created!'),
          });
      },
      onError: (error) => {
        notify({
          type: AppNotificationTypes.Error,
          message: t(
            'creating_link_error',
            'An error occured while creating your link.',
          ),
        });
      },
    },
  );

  return {
    createBacklink,
    ...rest,
  };
}

/**
 * Update Backlink Mutation Hook
 * wraps a react-query {@link useMutation}
 * the react-query {@link QueryKey} used in the correspondig fetch query for the item we update
 */
export const useUpdateBacklink = ({
  queryDataKey,
}: {
  queryDataKey: QueryKey;
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const {
    mutate: updateBacklink,
    status: updateStatus,
    ...rest
  } = useMutation(backlinkApi.updateBacklink, {
    onSuccess: (data) => {
      // update current backlink with new data
      if (queryDataKey) queryClient.setQueryData(queryDataKey, data);
      notify({
        type: AppNotificationTypes.Success,
        message: t('link_updated', 'Link updated!'),
      });
    },
    onError: (error) => {
      notify({
        type: AppNotificationTypes.Error,
        message: t(
          'updating_link_error',
          'An error occured while updating your link',
        ),
      });
    },
  });

  return {
    updateBacklink,
    updateStatus,
    ...rest,
  };
};

/**
 * Update Backlink Mutation Hook
 * wraps a react-query {@link useMutation}
 * the react-query {@link QueryKey} used in the correspondig fetch query for the item we update
 */
export const useUpdateBacklinkV2 = ({
  queryDataKey,
}: {
  queryDataKey: QueryKey;
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const {
    mutate: updateBacklink,
    status: updateStatus,
    ...rest
  } = useMutation(backlinkApi.updateBacklinkV2, {
    onSuccess: (data) => {
      // update current backlink with new data
      if (queryDataKey) queryClient.setQueryData(queryDataKey, data);
      notify({
        type: AppNotificationTypes.Success,
        message: t('link_updated', 'Link updated!'),
      });
    },
    onError: (error) => {
      notify({
        type: AppNotificationTypes.Error,
        message: t(
          'updating_link_error',
          'An error occured while updating your link',
        ),
      });
    },
  });

  return {
    updateBacklink,
    updateStatus,
    ...rest,
  };
};

export function useDeleteBacklink() {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });
  const { notify } = useNotification();
  const queryClient = useQueryClient();

  const { mutate: deleteBacklink, ...rest } = useMutation(
    backlinkApi.deleteBacklink,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['backlinks']);
        notify({
          type: AppNotificationTypes.Success,
          message: t('link_deleted', 'Link deleted!'),
        });
      },
      onError: (error) => {
        notify({
          type: AppNotificationTypes.Error,
          message: t(
            'deleting_link_error',
            'An error occured while deleting your link.',
          ),
        });
      },
    },
  );

  return {
    deleteBacklink,
    ...rest,
  };
}

export function useRefreshBacklink() {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });
  const { notify } = useNotification();
  const queryClient = useQueryClient();

  const { mutate: refreshBacklink, ...rest } = useMutation(
    backlinkApi.refreshBacklink,
    {
      onSuccess: (data) => {
        if (!data) {
          notify({
            type: AppNotificationTypes.Error,
            message: t(
              'link_refreshed_error',
              'An error occured while refreshing your link.',
            ),
          });
          return;
        }

        notify({
          type: AppNotificationTypes.Success,
          message: t('link_refreshed', 'Link refreshed!'),
        });
        // use response data to avoid having to invalidate & refetch
        queryClient.setQueryData(['backlink', data.id], data);
        // refresh the backlinks list to load the new data
        queryClient.invalidateQueries(['backlinks']);
      },
      onError: (error) => {
        notify({
          type: AppNotificationTypes.Error,
          message: t(
            'link_refreshed_error',
            'An error occured while refreshing your link.',
          ),
        });
      },
    },
  );

  return {
    refreshBacklink,
    ...rest,
  };
}

/**
 * this hook is used to get a backlinks export (admin side)
 * it will first call the route that will trigger the export
 * then periodically check the status of the export job via the dedicated route
 * and update a global variable to indicate whether the job is still running or if it's completed
 *
 * the trigger/'get downwload url' route is passed the filters that are active at the time of the export
 * i.e. the exact same payload that is used in the fetch all backlinks route
 * the status route is passed a 'reference' that is returned by the trigger route
 */
// TODO: Refactor into a generic hook (useDownloadWithPolling or similar)
export function useExportAdminBacklinks(
  requestPayload: FetchAdminBacklinksPayload,
) {
  const { t } = useTranslation('common', {
    keyPrefix: 'entity.backlink.message',
  });

  const { notify } = useNotification();

  /**
   * we want to store the export reference returned in the first request
   * to be able to check the status of the export job
   */
  const exportRef = React.useRef<string>();

  // a global Export status
  const [exportStatus, setExportStatus] =
    React.useState<ExportDataStatusEnum>();

  // a global status to indicate whether the export job has been triggered and is still running
  const [isPreparingExport, setIsPreparingExport] = React.useState(false);

  // a ref for the timeout we'll need to be able to clear when the component unmounts or the export is retriggered
  const pollingTimeoutRef = React.useRef<number>();
  React.useEffect(() => {
    return () => {
      window.clearTimeout(pollingTimeoutRef.current);
    };
  }, []);

  // a ref to keep track of how many times we've polled the api
  // and adjust the polling interval accordingly
  const pollingCountRef = React.useRef(0);

  // common success/error callbacks for both trigger export and export status calls
  const callbacks = {
    // the bakend has responded with a 200 or a 201
    onSuccess: (response: ExportAdminBacklinksResponse) => {
      setExportStatus(response.lastStatus);

      if (response.hashReference) {
        exportRef.current = response.hashReference;
      }

      // done but we need to call the route that will give us the export url
      if (
        response.lastStatus === ExportDataStatusEnum.COMPLETED &&
        !response.downloadUrl
      ) {
        exportAdminBacklinks(requestPayload);
        // reset polling count
        pollingCountRef.current = 0;
      }
      // now we're done ...
      else if (
        response.lastStatus === ExportDataStatusEnum.COMPLETED &&
        response.downloadUrl
      ) {
        setIsPreparingExport(false);

        downloadFileAtUrl(response.downloadUrl);
        notify({
          type: AppNotificationTypes.Success,
          message: t('export_backlink_success', 'Backlinks export successful'),
        });
      }
      // in progress
      else if (
        response.lastStatus === ExportDataStatusEnum.QUEUED ||
        response.lastStatus === ExportDataStatusEnum.STARTED
      ) {
        const pollingInterval =
          pollingCountRef.current > 4
            ? POLLING_LONG_INTERVAL
            : POLLING_DEFAULT_INTERVAL;
        pollingCountRef.current += 1;
        pollingTimeoutRef.current = window.setTimeout(() => {
          if (exportRef.current) {
            exportAdminBacklinksStatus({
              hashReference: exportRef.current,
            });
          }
        }, pollingInterval);
      } else if (response.lastStatus === ExportDataStatusEnum.FAILED) {
        setIsPreparingExport(false);
      }
    },
    onError: (error: AxiosError) => {
      setIsPreparingExport(false);

      notify({
        type: AppNotificationTypes.Error,
        message: t(
          'export_backlink_error',
          'An error occured while exporting your backlinks.',
        ),
      });
    },
  };

  // mutation to trigger the export and get the downloadUrl when it's done
  const { mutate: exportAdminBacklinks, ...rest } = useMutation(
    backlinkApi.exportAdminBacklinks,
    {
      onMutate: () => {
        setIsPreparingExport(true);
        window.clearTimeout(pollingTimeoutRef.current);
      },
      ...callbacks,
    },
  );

  // mutation to poll the export job status
  const { mutate: exportAdminBacklinksStatus } = useMutation(
    backlinkApi.exportAdminBacklinksStatus,
    {
      ...callbacks,
    },
  );

  return {
    exportAdminBacklinks,
    isPreparingExport,
    exportStatus,
    exportHasFailed: exportStatus === ExportDataStatusEnum.FAILED,
    ...rest,
  };
}

export function useFetchBacklinksPerformance(
  params: FetchBacklinksPerformancePayload,
  options?: UseQueryOptions<
    BacklinksPerformanceResponse,
    unknown,
    BacklinksPerformanceResponse,
    [string, FetchBacklinksPerformancePayload]
  >,
) {
  const { data, ...rest } = useQuery(
    ['backlinksPerformance', params],
    () => backlinkApi.fetchBacklinksPerformance(params),
    options,
  );

  return { backlinksPerformance: data?.performance, ...rest };
}
