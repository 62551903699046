import React from 'react';

import { TYPE_OPTIONS } from 'shared/components/ActionEditor/constants';
import PlatformUrlField from 'shared/components/PlatformUrlField';
import { PlatformConfig } from 'shared/entities/action/action.types';
import { DSP } from 'shared/entities/storeConfig/streamingPlatform.types';

import { Actions, ActionsTypes } from '../../state';

import * as Styled from './styled';

type Props = {
  type: string;
  platformName: DSP;
  platformConfig?: PlatformConfig;
  dispatch: React.Dispatch<Actions>;
};

const UrlField: React.FC<Props> = ({
  type,
  platformName,
  platformConfig,
  dispatch,
}) => (
  <Styled.Container>
    <PlatformUrlField
      resourceId={platformConfig?.targetId}
      resourceUrl={platformConfig?.targetUrl}
      onUrlResolved={({ resourceId, resourceUrl }) => {
        dispatch({
          type: ActionsTypes.SetPlatformConfig,
          payload: {
            platform: platformName,
            config: {
              targetUrl: resourceUrl,
              targetId: resourceId,
            },
          },
        });
      }}
      onUrlCleared={() => {
        dispatch({
          type: ActionsTypes.RemovePlatform,
          payload: platformName,
        });
      }}
      onUrlIsBeingChecked={(value) =>
        dispatch({
          type: ActionsTypes.SetUrlIsBeingChecked,
          payload: {
            platform: platformName,
            value: value,
          },
        })
      }
      platformName={platformName}
      targetType={TYPE_OPTIONS[type].targetType}
    />
  </Styled.Container>
);

export default UrlField;
