import React from 'react';

import { ButtonBaseDanger } from 'App.styled';
import { useTranslation } from 'react-i18next';

import {
  Edit as EditIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

import { Action } from 'shared/entities/action/action.types';

import { TYPE_OPTIONS, SCHEDULE_TYPE_OPTIONS } from '../constants';

import { StyledCard, StyledCardHeader } from './styled';

type Props = {
  action?: Action;
  isEditing: boolean;
  onEditAction: () => void;
  onRemoveAction: () => void;
  isDefaultAction: boolean;
};

const ActionCard: React.FC<Props> = ({
  action,
  onEditAction,
  onRemoveAction,
  isDefaultAction,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCard
      role="group"
      aria-label={`${t(
        'ui.component.dsp_action_editor.action_card',
        'Action Card',
      )} - ${action ? TYPE_OPTIONS[action.type].displayName : ''} - ${
        action ? action.targetName : ''
      }`}
      elevation={0}
      color="primary"
      isDefaultAction={!!isDefaultAction}
    >
      {action && (
        <StyledCardHeader
          title={TYPE_OPTIONS[action.type].displayName}
          subheader={action.targetName}
          action={
            <>
              <Typography variant="body1">
                {action.scheduleType &&
                  SCHEDULE_TYPE_OPTIONS[action.scheduleType].displayName}
              </Typography>
              {isDefaultAction ? null : (
                <>
                  <IconButton
                    aria-label={t(
                      'ui.component.dsp_action_editor.edit_action',
                      'Edit action',
                    )}
                    color="primary"
                    onClick={() => onEditAction()}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label={t(
                      'ui.component.dsp_action_editor.remove_action',
                      'Remove action',
                    )}
                    component={ButtonBaseDanger}
                    onClick={() => onRemoveAction()}
                    size="large"
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </>
              )}
            </>
          }
        />
      )}
    </StyledCard>
  );
};

export default ActionCard;
