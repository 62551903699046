import { QueryKey, useMutation, useQueryClient } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import * as artistApi from 'shared/entities/artist/artist.api';
import { useNotification } from 'shared/hooks/useNotification';

type Params = {
  /**
   * {@link QueryKey} for the fetch query we need to invalidate to refresh data
   */
  queryDataKey: QueryKey;
};

const useUpdateArtistProfile = ({ queryDataKey }: Params) => {
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const {
    mutate: updateArtistProfile,
    status: updateArtistProfileStatus,
    ...rest
  } = useMutation(artistApi.updateArtistProfile, {
    onSuccess: (data) => {
      notify({
        type: AppNotificationTypes.Success,
        message: `Artist updated`,
      });
      if (queryDataKey) queryClient.setQueryData(queryDataKey, data);
    },
    onError: (error) => {
      notify({
        type: AppNotificationTypes.Error,
        message: `An error occured while updating the artist profile. ${error}`,
      });
    },
  });

  return {
    updateArtistProfile,
    updateArtistProfileStatus,
    ...rest,
  };
};

export default useUpdateArtistProfile;
