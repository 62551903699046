import { useTranslation } from 'react-i18next';
import { QueryKey, useMutation, useQueryClient } from 'react-query';

import { AppNotificationTypes } from 'shared/constants';
import { BacklinkSettings } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useNotification } from 'shared/hooks/useNotification';

type Params<TUpdaterParams> = {
  updater: (params: TUpdaterParams) => Promise<BacklinkSettings>;
  /** the react-query {@link QueryKey} used in the corresponding fetch query for the item we update */
  queryDataKey: QueryKey;
};

/**
 * Update Workspace BacklinkSettings Mutation Hook
 * wraps a react-query {@link useMutation}
 */
const useUpdateParentEntityBacklinkSettings = <TUpdaterParams>({
  updater,
  queryDataKey,
}: Params<TUpdaterParams>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const {
    mutate: updateBacklinkSettings,
    status: updateStatus,
    ...rest
  } = useMutation(updater, {
    onSuccess: (backlinkSettings) => {
      if (queryDataKey)
        queryClient.setQueryData(queryDataKey, backlinkSettings.data);
      notify({
        type: AppNotificationTypes.Success,
        message: t(
          'entity.backlink_settings.message.link_settings_updated',
          'Link Settings updated!',
        ),
      });
    },
    onError: (error) => {
      notify({
        type: AppNotificationTypes.Error,
        message: t(
          'entity.backlink_settings.message.updating_link_settings_error',
          'An error occured while updating the Link Settings.',
        ),
      });
    },
  });

  return {
    updateBacklinkSettings,
    updateStatus,
    ...rest,
  };
};

export default useUpdateParentEntityBacklinkSettings;
