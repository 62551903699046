import moment from 'moment';

import {
  Action,
  ActionScheduleTypeEnum,
  ActionTypeEnum,
} from 'shared/entities/action/action.types';

import { ActionEditorState, Actions, ActionsTypes } from '.';

const makeEmptyAction = (): Action => ({
  id: '',
  type: ActionTypeEnum.ADD_ALBUM,
  scheduleType: ActionScheduleTypeEnum.SCHEDULED_NOW,
  targetName: '',
  config: { platforms: {} },
});

export function reducer(
  state: ActionEditorState,
  action: Actions,
): ActionEditorState {
  switch (action.type) {
    case ActionsTypes.SetType: {
      return {
        ...state,
        editedAction: {
          ...makeEmptyAction(),
          ...state.editedAction,
          type: action.payload,
        },
      };
    }

    case ActionsTypes.SetTargetName: {
      return {
        ...state,
        editedAction: {
          ...makeEmptyAction(),
          ...state.editedAction,
          targetName: action.payload,
        },
      };
    }

    case ActionsTypes.SetScheduleType: {
      const scheduleType = action.payload;
      const newAction = {
        ...makeEmptyAction(),
        ...state.editedAction,
        scheduleType,
      };
      // If action is scheduled for now, remove the schedulableOn field
      if (scheduleType === ActionScheduleTypeEnum.SCHEDULED_NOW) {
        delete newAction.schedulableOn;
      } else if (scheduleType === ActionScheduleTypeEnum.SCHEDULED_WITH_DATE) {
        // If action is scheduled to a specific date, restore a previous schedulableOn
        // date, or default to the day after
        newAction.schedulableOn =
          newAction.schedulableOn ||
          moment().add(1, 'days').format('YYYY-MM-DD');
      }
      return {
        ...state,
        editedAction: newAction,
      };
    }

    case ActionsTypes.SetSchedulableOn: {
      const schedulableOn = action.payload;
      return {
        ...state,
        editedAction: {
          ...makeEmptyAction(),
          ...state.editedAction,
          schedulableOn,
        },
      };
    }

    case ActionsTypes.SetSelectedTimezone: {
      return {
        ...state,
        editedAction: {
          ...makeEmptyAction(),
          ...state.editedAction,
          config: {
            platforms: {},
            ...state.editedAction?.config,
            selectedTimezone: action.payload,
          },
        },
      };
    }

    case ActionsTypes.SetPlatformConfig: {
      const { platform, config } = action.payload;
      return {
        ...state,
        editedAction: {
          ...makeEmptyAction(),
          ...state.editedAction,
          config: {
            ...state.editedAction?.config,
            platforms: {
              ...state.editedAction?.config.platforms,
              [platform]: config,
            },
          },
        },
      };
    }

    case ActionsTypes.RemovePlatform: {
      const platforms = state.editedAction?.config.platforms;
      if (platforms && platforms[action.payload]) {
        delete platforms[action.payload];
      }
      return {
        ...state,
        editedAction: {
          ...makeEmptyAction(),
          ...state.editedAction,
          config: {
            ...state.editedAction?.config,
            platforms: platforms || {},
          },
        },
      };
    }

    case ActionsTypes.SetUrlIsBeingChecked: {
      const { platform, value } = action.payload;
      const { platformUrlIsBeingChecked } = state;
      platformUrlIsBeingChecked[platform] = value;
      return {
        ...state,
        platformUrlIsBeingChecked,
      };
    }

    case ActionsTypes.SetErrors: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
