import {
  FormFieldName,
  FormFieldType,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';

import { BacklinkSettingsType as BacklinkSettingsTypeV2 } from '../../backlinkSettings.constants';
import {
  BacklinkSettings,
  BacklinkSettingsType,
} from '../../backlinkSettings.types';
import { BacklinkSettings as BacklinkSettingsV2 } from '../../v2/types';
import { transformBacklinkSettingsDataV1ToV2 } from './backlinkSettings.fixture.helpers';
import {
  common,
  releaseStep,
  designStep,
  prereleaseLandingStep,
  prereleasePresaveStep,
  prereleaseFormStep,
  prereleaseAutonotifyStep,
  postreleaseLandingStep,
  sharingStep,
} from './backlinkSettings.fixture.steps';

export const workspaceCustomBacklinkSettings: BacklinkSettings = {
  id: '94fbc3a0-fc47-44d5-ae5b-fb92dd001c6b',
  type: BacklinkSettingsType.REL_WORKSPACE,
  data: {
    common,
    releaseStep,
    designStep,
    prereleaseLandingStep,
    prereleasePresaveStep,
    prereleaseFormStep,
    prereleaseAutonotifyStep,
    postreleaseLandingStep,
    sharingStep,
  },
};

export const workspaceCustomBacklinkSettingsV2: BacklinkSettingsV2 = {
  id: '94fbc3a0-fc47-44d5-ae5b-fb92dd001c6b',
  type: BacklinkSettingsTypeV2.REL_WORKSPACE,
  data: transformBacklinkSettingsDataV1ToV2(
    workspaceCustomBacklinkSettings.data,
  ),
};

export const artistCustomBacklinkSettings: BacklinkSettings = {
  id: '43f5fc60-f962-422f-90e9-58fa3930b0e8',
  type: BacklinkSettingsType.REL_ARTIST,
  data: {
    common,
    releaseStep,
    designStep,
    prereleaseLandingStep,
    prereleasePresaveStep,
    prereleaseFormStep,
    prereleaseAutonotifyStep,
    postreleaseLandingStep,
    sharingStep,
  },
};

export const artistCustomBacklinkSettingsV2: BacklinkSettingsV2 = {
  id: '43f5fc60-f962-422f-90e9-58fa3930b0e8',
  type: BacklinkSettingsTypeV2.REL_ARTIST,
  data: transformBacklinkSettingsDataV1ToV2(artistCustomBacklinkSettings.data),
};

export const backlinkBacklinkSettings: BacklinkSettings = {
  id: 'b641fe2e-09c7-40c0-888f-bd98278bf427',
  type: BacklinkSettingsType.REL_BACKLINK,
  data: {
    common: {
      ...common,
      defaultActionId: 'bb671e5c-47ab-4f55-9e93-3f4e6311ec1a',
    },
    releaseStep: {
      ...releaseStep,
      release: {
        ...releaseStep.release,
        digitalReleaseDate: '2050-03-27T20:00:00Z',
        title: 'An aural symbiotic mystery',
      },
    },
    designStep,
    prereleaseLandingStep: {
      ...prereleaseLandingStep,
      locale: {
        ...prereleaseLandingStep.locale,
        fr: {
          ...prereleaseLandingStep.locale.fr,
          richText:
            '<h2>Nouveau single "An aural symbiotic mystery"</h2>\n<p>Ne manque pas la sortie le 28 mars ! Choisis ta plateforme préférée :</p>',
        },
        en: {
          ...prereleaseLandingStep.locale.en,
          richText:
            '<h2>New single "An aural symbiotic mystery"</h2>\n<p>Out on March 28! Select your favorite platform:</p>',
        },
      },
    },
    prereleasePresaveStep: {
      ...prereleasePresaveStep,
      locale: {
        ...prereleasePresaveStep.locale,
        fr: {
          ...prereleasePresaveStep.locale.fr,
          notificationText:
            'An aural symbiotic mystery sera ajouté à ta librairie le 28 mars!',
        },
        en: {
          ...prereleasePresaveStep.locale.en,
          notificationText:
            'An aural symbiotic mystery will be added to your library on 28 march!',
        },
      },
    },
    prereleaseFormStep: {
      ...prereleaseFormStep,
      locale: {
        ...prereleaseFormStep.locale,
        fr: {
          ...prereleaseFormStep.locale.fr,
          richText:
            '<h1 style="text-align: center;">Presque fini 🔥</h1>\n<p style="text-align: center;">Complète tes infos pour être notifié·e à la sortie :</p>',
          form: {
            ...prereleaseFormStep.locale.fr.form,
            optins: [
              {
                required: false,
                name: FormFieldName.OptinSubscription,
                label:
                  "J'accepte de recevoir news et offres de Charlemagne Palestine par Believe. J'ai plus de 15 ans.",
                type: FormFieldType.Checkbox,
                metas: { consentToOffers: true, consentToNews: true },
              },
              {
                name: FormFieldName.OptinCompetition,
                type: FormFieldType.Checkbox,
                label:
                  'J\'accepte le <a href="/?screen=rules">Règlement</a> du jeu-concours.',
                required: true,
                metas: {
                  startDate: '2024/01/02',
                  endDate: '2024/01/03',
                  minimumAge: 15,
                  territory: 'France',
                  winnersCount: 0,
                  prizes: `<ul>
             <li>
               1 prix de rang 1 : [Description] - [Valeur]
             </li>
             <li>
               1 prix de rang 2 : [Description] - [Valeur]
             </li>
             <li>
               1 prix de rang 3 : [Description] - [Valeur]
             </li>
           </ul>`,
                },
              },
            ],
          },
        },
        en: {
          ...prereleaseFormStep.locale.en,
          richText:
            '<h1 style="text-align: center;">🔥 Almost done!</h1>\n<p style="text-align: center;">Submit your info to get notified on release:</p>',
          form: {
            ...prereleaseFormStep.locale.en.form,
            optins: [
              {
                required: false,
                name: FormFieldName.OptinSubscription,
                label:
                  '<p>Send me future news and offers of Charlemagne Palestine from Believe. I am over 15 y.o.</p>',
                type: FormFieldType.Checkbox,
                metas: { consentToOffers: true, consentToNews: true },
              },
              {
                name: FormFieldName.OptinCompetition,
                type: FormFieldType.Checkbox,
                label:
                  '<p>I agree to the Sweepstakes <a href="../../../../?screen=rules">T&amp;Cs.</a></p>',
                required: true,
                metas: {
                  startDate: '2024/01/02',
                  endDate: '2024/01/03',
                  minimumAge: 15,
                  territory: 'France',
                  winnersCount: 0,
                  prizes: `<ul>\n<li>1 prize of rank 1: [Description] - [Value]</li>\n<li>1 prize of rank 2: [Description] - [Value]</li>\n<li>1 prize of rank 3: [Description] - [Value]</li>\n</ul>`,
                },
              },
            ],
          },
        },
      },
    },
    prereleaseAutonotifyStep,
    postreleaseLandingStep: {
      ...postreleaseLandingStep,
      locale: {
        ...postreleaseLandingStep.locale,
        fr: {
          ...postreleaseLandingStep.locale.fr,
          richText:
            '<h2 style="text-align: center;">Charlemagne Palestine - An aural symbiotic mystery</h2>\n<p style="text-align: center;">Choisis ta plateforme préférée :</p>',
        },
        en: {
          ...postreleaseLandingStep.locale.en,
          richText:
            '<h2 style="text-align: center;">Charlemagne Palestine - An aural symbiotic mystery</h2>\n<p style="text-align: center;">Select your favorite platform:</p>',
        },
      },
    },
    sharingStep: {
      url: { subDomain: 'palestine', slug: 'an-aural-symbiotic-mystery' },
      socialCard: {
        imageId: '2a86acb8-d2b2-47a1-8faf-bea07260541a',
        title: 'Charlemagne Palestine — An aural symbiotic mystery',
        description: 'Sortie le March 28, 2007',
      },
    },
    preview: {
      dspActionsInfosByPlatform: {
        spotify: [
          {
            id: 'bb671e5c-47ab-4f55-9e93-3f4e6311ec1a',
            displayName: 'An aural symbiotic mystery',
            linkEnabled: false,
            url: '',
          },
        ],
        deezer: [
          {
            id: 'bb671e5c-47ab-4f55-9e93-3f4e6311ec1a',
            displayName: 'An aural symbiotic mystery',
            linkEnabled: false,
            url: '',
          },
        ],
        appleMusic: [
          {
            id: 'bb671e5c-47ab-4f55-9e93-3f4e6311ec1a',
            displayName: 'An aural symbiotic mystery',
            linkEnabled: false,
            url: '',
          },
        ],
      },
    },
  },
};

export const backlinkBacklinkSettingsV2: BacklinkSettingsV2 = {
  id: 'b641fe2e-09c7-40c0-888f-bd98278bf427',
  type: BacklinkSettingsTypeV2.REL_BACKLINK,
  data: transformBacklinkSettingsDataV1ToV2(backlinkBacklinkSettings.data),
};
