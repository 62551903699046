import { Artist } from '../../artist.types';

export const artists: Artist[] = [
  {
    id: '8f6dca87-5c8b-4237-b540-4cbb76e92575',
    createdAt: '2021-04-28T14:26:49.869Z',
    updatedAt: '2021-06-08T13:24:05.883Z',
    name: 'Petit Biscuit',
    data: {
      profilePictureId: 'b78c4dbf-efb4-47d9-b6ab-04b0a4ea95cf',
      instagramHandle: 'petitbiscuit',
      facebookHandle: 'petitbiscuit',
      twitterHandle: '',
      tiktokHandle: '',
      VK: '',
      youtubeChannelName: '',
      subDomain: 'petit-biscuit',
      storeProfileLinks: {
        bandcamp: { url: 'https://petitbiscuit.bandcamp.com/' },
        itunes: {
          url: 'https://itunes.apple.com/us/artist/petit-biscuit/id1138366983',
        },
        spotify: {
          url: 'https://open.spotify.com/artist/0z8XQXZ6X6QX5XQX5XQX5',
          id: 'zd3423423xD',
        },
      },
      storeOfficialPlaylists: {
        deezer: {
          url: 'https://www.deezer.com/fr/playlist/5269041922',
          id: 'deezer',
        },
      },
    },
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: null,
  },
  {
    id: '04f54e02-e837-4573-9c48-40b45df2fbba',
    createdAt: '2021-05-25T07:08:41.561Z',
    updatedAt: '2021-06-08T15:08:52.660Z',
    name: 'Aymen Al Deeb',
    data: {
      profilePictureId: '',
      instagramHandle: 'test',
      facebookHandle: 'hello',
      twitterHandle: '',
      tiktokHandle: '',
      VK: '',
      youtubeChannelName: '',
      subDomain: 'aymen',
      storeOfficialPlaylists: {
        spotify: {
          url: 'https://open.spotify.com/playlist/37i9dQZF1DZ06evO3HqSI0',
        },
        deezer: {
          url: 'https://www.deezer.com/fr/playlist/5269041922',
          id: 'deezer',
        },
      },
    },
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: 'af84c5a5-f431-455b-8695-2b3047889863',
  },
  {
    id: '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea',
    createdAt: '2021-06-16T09:55:52.259Z',
    updatedAt: '2021-06-16T09:55:52.259Z',
    name: 'Charlemagne Palestine',
    data: {
      subDomain: 'palestine',
      storeProfileLinks: {
        bandcamp: { url: 'https://petitbiscuit.bandcamp.com/' },
        itunes: {
          url: 'https://itunes.apple.com/us/artist/palestine/id1138366983',
        },
        appleMusic: {
          url: 'https://apple-music.com/us/artist/palestine/id1138366983',
        },
        spotify: {
          url: 'https://open.spotify.com/artist/0z8XQXZ6X6QX5XQX5XQX5',
          id: 'zd3423423xD',
        },
        deezer: {
          url: 'https://open.deezer.com/artist/0z8XQXZ6X6QX5XQX5XQX5',
          id: 'zd3423423xD',
        },
        fnac: {
          url: 'https://fnac.com',
        },
        napster: {
          url: 'https://napster.com',
        },
        youtube: {
          url: 'https://youtube.com',
        },
        youtubeMusic: {
          url: 'https://youtube-music.com',
        },
      },
    },
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: null,
  },
  {
    id: 'f912c40c-8657-442a-9fdf-2d4fe212c518',
    createdAt: '2021-06-04T16:49:33.209Z',
    updatedAt: '2021-06-04T16:49:33.209Z',
    name: 'Christian Tanz',
    data: {},
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: null,
  },
  {
    id: 'e08b16d9-18d6-41d2-876a-d5b62775c9db',
    createdAt: '2021-06-08T08:48:18.480Z',
    updatedAt: '2021-06-08T08:48:18.480Z',
    name: 'Cliff Richard',
    data: {},
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: null,
  },
  {
    id: '4a0fbfa7-7fcd-48e9-be38-a92057128691',
    createdAt: '2021-06-01T13:02:53.385Z',
    updatedAt: '2021-06-03T09:07:51.215Z',
    name: 'Dick Rivers',
    data: {
      profilePictureId: '058949fc-f253-42d8-a764-4bd1430e7544',
      instagramHandle: 'test',
      facebookHandle: '',
      twitterHandle: '',
      tiktokHandle: '',
      VK: '',
      youtubeChannelName: '',
      subDomain: '',
    },
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: '0788dc6f-5268-42c6-bb33-64f4b9e66e3d',
  },
  {
    id: '400c0bb5-57a3-429f-b62c-1d1a535f0407',
    createdAt: '2021-06-15T05:11:53.803Z',
    updatedAt: '2021-06-15T05:11:53.803Z',
    name: 'Donatello',
    data: {},
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: null,
  },
  {
    id: 'efb1c4a6-4b72-4194-8183-8943e4c13dcc',
    createdAt: '2021-04-29T11:38:26.053Z',
    updatedAt: '2021-06-03T14:21:09.514Z',
    name: 'Enrico Traverso',
    data: {
      instagramHandle: 'enricoinsta',
      facebookHandle: 'enricofb',
      twitterHandle: 'enricotwitter',
      tiktokHandle: 'enricotiktok',
      VK: 'enricovk',
      youtubeChannelName: 'enricoyoutube',
      subDomain: 'enricosub',
      detail: 'NB',
      mainCountryCode: 'DE',
      mainCountryName: 'Germany',
      BUCountryCode: 'DE',
      BUCountry: 'Germany',
      youtubeBelieveCMSName: 'NOT IN CMS',
      BURegion: 'Germany',
      mainSubGenreId: 111,
      mainSubGenreName: 'French Hip Hop',
      mainGenreId: 8,
      mainGenreName: 'Hip Hop/Rap',
      youtubeChannelId: 'UCtMCDk8R91ew7U',
      youtubeChannelUrl:
        'https://www.youtube.com/channel/UCtMCDk8R91ew7U-uIGrSgOQ',
      instagramBusinessManager: 'yes',
      instagramId: '17841409017710500',
      facebookBusinessManager: 'yes',
      facebookId: 100492178474565,
      douyinHandle: 'enricodoyin',
      snapHandle: 'enricosnap',
      twitchHandle: 'enricotwitch',
      chartmetricId: 3945265,
      crowdTangleInstagramAccountId: 11376794,
      crowdTangleFacebookAccountId: 11376794,
      crowdTangleInstagramListId: 11376794,
      crowdTangleFacebookListId: 11376794,
      idArtistIdProducer: '298811-774973',
      classification: 'Emerging Artist',
      signatureDate: '2020-02-28',
      creationDate: '2020-02-28',
      categoryCode: 1,
      googleTrendsKeyword: 'enricogoogletrends',
      profilePictureId: '02d61f13-1e7e-45a3-9851-ca2fccb734a1',
    },
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: '5562d622-8f31-4631-9e58-2d16a50b6f75',
    producerIds: ['774973'],
    internalArtistIds: ['298811'],
  },
  {
    id: 'aab203a9-6cc2-4095-a32f-3f77678accd0',
    createdAt: '2021-05-17T14:43:10.828Z',
    updatedAt: '2021-05-17T14:43:10.828Z',
    name: 'Flóp',
    data: {},
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: null,
  },
  {
    id: '84cddc53-3045-4ab3-b3d8-5d2d68a6e428',
    createdAt: '2021-06-18T12:24:21.444Z',
    updatedAt: '2021-06-18T12:24:21.444Z',
    name: 'Francesco Ruoppolo',
    data: {},
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: null,
  },
  {
    id: 'ec221c73-5996-48ef-96fb-356cd97f642a',
    createdAt: '2021-06-03T12:31:59.745Z',
    updatedAt: '2021-06-04T16:43:32.337Z',
    name: 'Gregsky',
    data: {
      profilePictureId: 'bb36806e-40eb-471f-b952-0684d6f63ee8',
      instagramHandle: '',
      facebookHandle: '',
      twitterHandle: '',
      tiktokHandle: '',
      VK: '',
      youtubeChannelName: '',
      subDomain: '',
      storeProfileLinks: {
        bandcamp: { url: 'https://petitbiscuit.bandcamp.com/' },
        itunes: {
          url: 'https://itunes.apple.com/us/artist/petit-biscuit/id1138366983',
        },
        spotify: {
          url: 'https://open.spotify.com/artist/0z8XQXZ6X6QX5XQX5XQX5',
          id: 'zd3423423xD',
        },
      },
    },
    workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
    customBacklinkSettingsId: null,
  },
];

export const getArtist = (artistId: string) => {
  return artists.find((artist) => artist.id === artistId);
};

export const getArtistFromLocalStorage = (artistId: string) => {
  //test updated artist data
  const localStorageArtist = localStorage.getItem('artistData');
  if (localStorageArtist) {
    const { updatedArtist } = JSON.parse(localStorageArtist);
    if (updatedArtist.id === artistId) {
      return updatedArtist;
    }
  }
  return getArtist(artistId);
};
