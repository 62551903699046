import React from 'react';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import Fuse from 'fuse.js';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useResolvedPath } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import OpenIcon from '@mui/icons-material/OpenInNew';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Button,
  Box,
  Tooltip,
  IconButton,
  useTheme,
  Link as MuiLink,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import ArtistEditRouteSetup from 'shared/components/ArtistEditor';
import { HIGHLIGHT_DOM_IDS } from 'shared/components/ArtistSettingsPanel/tabs/Profile';
import * as Popover from 'shared/components/Popover';
import { Artist } from 'shared/entities/artist/artist.types';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { buildStoreLogoLabelUri } from 'shared/services/assets.service';
import { useAppWideData } from 'shared/state';

import { useBacklinkSettingsDataEditorContext } from '../../../../state/context';

import * as EditorStyled from '../../../styled';
import * as Styled from '../../styled';

/*********************************************************************/
// Pre-release Autonotify Step - Stores manager
/*********************************************************************/
type StoresManagerProps = {
  selectedStores: string[];
  onChange: (newStores: string[]) => void;
};

const StoresManager = ({ selectedStores, onChange }: StoresManagerProps) => {
  const { t } = useTranslation('common', {
    keyPrefix:
      'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.artist_urls.fields.stores',
  });

  const { parentEntityInfos } = useBacklinkSettingsDataEditorContext();
  const backlink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : undefined;
  const { data: artist } = useAppQuery('artist', {
    fetcherArgs: [backlink?.artist?.id as string],
    enabled: !!backlink?.artist,
  });

  return (
    <>
      <Box sx={{ marginTop: '40px', marginBottom: '40px !important' }}>
        {!!artist && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span css={{ fontSize: '1rem', fontWeight: 600 }}>
              {t('title', 'Stores (6 max)')}
            </span>
            <StoreSelector {...{ artist, selectedStores, onChange }} />
            <ArtistEditRouteSetup />
          </Box>
        )}
        <Styled.StepParagraph css={{ marginTop: 0 }}>
          {t(
            'description',
            'Select the stores you want to display in your email.',
          )}
        </Styled.StepParagraph>
      </Box>
      <StoreReorderer {...{ selectedStores, onChange, artist }} />
    </>
  );
};

export default StoresManager;

/*********************************************************************/
// Stores selector
// Choose stores from the artist profile
/*********************************************************************/
type StoreSelectorProps = StoresManagerProps & {
  artist: Artist;
};

const StoreSelector = ({
  artist,
  selectedStores,
  onChange,
}: StoreSelectorProps) => {
  const { t } = useTranslation('common', {
    keyPrefix:
      'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.artist_urls.fields.stores',
  });
  const routeUrl = useResolvedPath('').pathname;

  const { storesConfigs } = useAppWideData();

  const availableStores = Object.keys(artist?.data?.storeProfileLinks || {});
  const availableStoresOptions = availableStores.map((storeName) => ({
    value: storeName,
    label:
      (storesConfigs &&
        storesConfigs[storeName] &&
        storesConfigs[storeName]?.displayName) ||
      '',
    checked: selectedStores.includes(storeName),
  }));

  const [searchInputValue, setSearchInputValue] = React.useState('');
  const filteredOptions =
    searchInputValue.length > 0
      ? new Fuse(availableStoresOptions, {
          shouldSort: true,
          keys: ['label'],
        })
          .search(searchInputValue)
          .map((result) => result.item)
      : availableStoresOptions;

  // We need to synchronize the selected stores with the available stores
  // If a store link is not in the artist profile anymore, it has to be removed
  // from the notification settings
  React.useEffect(() => {
    const selectedStoresInArtistProfile = selectedStores.filter((store) =>
      availableStores.includes(store),
    );
    if (selectedStoresInArtistProfile.length !== selectedStores.length) {
      onChange(selectedStoresInArtistProfile);
    }
    // onChange is not stable so we need to ignore exhaustive deps this time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStores, availableStores]);

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button
          variant="contained"
          color="primary"
          sx={{
            minWidth: 'unset',
            borderRadius: '6px',
            padding: '6px 10px',
          }}
        >
          {t('buttons.add_store', '+ Add')}
        </Button>
      </Popover.Trigger>
      <Popover.Panel
        sx={{ marginLeft: '10px', '.MuiPopover-paper': { marginTop: 0 } }}
      >
        <Popover.Header>
          <Popover.HeaderTitle>
            {t('add_stores.title', 'Stores (6 max)')}
          </Popover.HeaderTitle>
          <Popover.CloseButton>
            <MuiLink
              to={`${routeUrl}/artist/${artist.id}/edit?highlight=${HIGHLIGHT_DOM_IDS.ARTIST_URLS}`}
              component={Link}
              sx={{
                color: 'primary',
                textDecoration: 'none',
                fontWeight: 600,
              }}
            >
              {t('manage_artist_urls', 'Manage artist URLs')}
            </MuiLink>
          </Popover.CloseButton>
        </Popover.Header>
        <Popover.Search
          value={searchInputValue}
          onChange={setSearchInputValue}
        />
        <Popover.Content>
          <FormGroup>
            {filteredOptions.map(({ value, checked, label }) => (
              <FormControlLabel
                key={value}
                control={
                  <Checkbox
                    color="primary"
                    name={value}
                    checked={checked}
                    onChange={() =>
                      onChange(
                        selectedStores.includes(value)
                          ? selectedStores.filter((s) => s !== value)
                          : [...selectedStores, value],
                      )
                    }
                  />
                }
                aria-label={value}
                label={label}
                disabled={
                  !checked &&
                  availableStoresOptions.filter((store) => store.checked)
                    .length >= 6
                }
              />
            ))}
          </FormGroup>
        </Popover.Content>
      </Popover.Panel>
    </Popover.Root>
  );
};

/*********************************************************************/
// Stores Reorderer
// Reorder the stores to customise the notification
/*********************************************************************/

type StoreReordererProps = StoresManagerProps & {
  artist?: Artist;
};

const StoreReorderer = ({
  selectedStores,
  onChange,
  artist,
}: StoreReordererProps) => {
  const { t } = useTranslation('common', {
    keyPrefix:
      'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.artist_urls.fields.stores',
  });
  const routeUrl = useResolvedPath('').pathname;
  const navigate = useNavigate();
  const theme = useTheme();

  function reorderStores(startIndex: number, endIndex: number) {
    const newStores = produce(selectedStores, (draft) => {
      const [removed] = draft.splice(startIndex, 1);
      draft.splice(endIndex, 0, removed);
    });
    onChange(newStores);
  }

  function handleDragEnd(result: DropResult) {
    if (!result.destination || !result.source) {
      return;
    }
    reorderStores(result.source.index, result.destination.index);
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="orderable-stores">
        {(provided) => (
          <div
            ref={provided.innerRef}
            role="list"
            aria-label={t('accessibility_name', 'Artist stores URLs')}
            {...provided.droppableProps}
          >
            {selectedStores.map((storeName, index) => (
              <Draggable key={storeName} draggableId={storeName} index={index}>
                {(provided) => (
                  <EditorStyled.DraggableItem
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    role="listitem"
                    aria-label={storeName}
                  >
                    <EditorStyled.DragHandle
                      {...provided.dragHandleProps}
                      style={{
                        top: '1rem',
                        left: '1rem',
                      }}
                    >
                      <EditorStyled.DragHandleIcon />
                    </EditorStyled.DragHandle>
                    <EditorStyled.Card
                      isExpanded={false}
                      sx={{ paddingLeft: 2.125, marginBottom: 1 }}
                    >
                      <EditorStyled.CardHeader
                        disableTypography
                        title={
                          <>
                            <Tooltip
                              title={
                                t('buttons.remove.tooltip', {
                                  storeName,
                                  defaultValue: 'Remove store {{storeName}}',
                                }) as string
                              }
                            >
                              <IconButton
                                aria-label={t(
                                  'buttons.remove.accessibility_name',
                                  {
                                    storeName,
                                    defaultValue: 'Remove store {{storeName}}',
                                  },
                                )}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onChange(
                                    selectedStores.filter(
                                      (store) => store !== storeName,
                                    ),
                                  );
                                }}
                                sx={{ marginRight: 1 }}
                                size="large"
                              >
                                <RemoveCircleIcon
                                  htmlColor={theme.palette.grey[600]}
                                />
                              </IconButton>
                            </Tooltip>
                            <img
                              src={buildStoreLogoLabelUri(storeName)}
                              alt={storeName}
                            />
                          </>
                        }
                        action={
                          !!artist && (
                            <>
                              <Tooltip
                                title={
                                  t('buttons.edit.tooltip', {
                                    defaultValue: 'Edit {{storeName}} URL',
                                    storeName,
                                  }) as string
                                }
                              >
                                <IconButton
                                  aria-label={t(
                                    'buttons.edit.accessibility_name',
                                    {
                                      defaultValue: 'Edit {{storeName}} URL',
                                      storeName,
                                    },
                                  )}
                                  onClick={() =>
                                    navigate(
                                      `${routeUrl}/artist/${artist.id}/edit?highlight=${HIGHLIGHT_DOM_IDS.ARTIST_URLS}`,
                                    )
                                  }
                                  size="large"
                                >
                                  <EditIcon
                                    htmlColor={theme.palette.grey[600]}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={
                                  t('buttons.open.tooltip', {
                                    defaultValue: 'Open {{storeName}} URL',
                                    storeName,
                                  }) as string
                                }
                              >
                                <IconButton
                                  aria-label={t(
                                    'buttons.open.accessibility_name',
                                    {
                                      defaultValue: 'Open {{storeName}} URL',
                                      storeName,
                                    },
                                  )}
                                  onClick={() =>
                                    window
                                      .open(
                                        artist.data.storeProfileLinks[storeName]
                                          .url,
                                        '_blank',
                                      )
                                      ?.focus()
                                  }
                                  size="large"
                                >
                                  <OpenIcon
                                    htmlColor={theme.palette.grey[600]}
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          )
                        }
                      />
                    </EditorStyled.Card>
                  </EditorStyled.DraggableItem>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
