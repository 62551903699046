import { InitOptions } from 'react-ga4/types/ga4';

import { UserEmployeePrograms } from 'shared/entities/auth/auth.types';
import { AppName } from 'shared/types';

//generic GA types
export type AppGATrackers = {
  ga4Trackers: readonly GA4InitOptions[];
};
export type GA4InitOptions = Omit<InitOptions, 'gtagOptions'> & {
  gtagOptions?: {
    /** ga4 snake case compulsory */
    user_properties?: GAUserProperties;
  };
};
export type GATrackers = {
  marketingSuite: AppGATrackers;
  backstageMarketing: AppGATrackers;
};

export type GAUserProperties = {
  userId: string;
  language: string;
  legalIdentity?: string;
  producerId?: string;
  userPrograms?: Array<UserEmployeePrograms>;
};

export enum GA4SubProducts {
  adsAS = 'adsAS',
  links = 'links',
  trackPitchTools = 'trackPitchTools',
  vmo = 'vmo',
  catalogOptimization = 'bsd',
  creative = 'creative',
  spotlight = 'spotlight',
}

/**
 * GA event and functions types
 */
export type GA4LegacyEventParams = {
  label?: string;
};

export type GA4BaseEventParams = {
  product: AppName;
  elementTitle?: string;
} & GA4LegacyEventParams;

// a type that represents all attributes used in a tracking event (regardless  of whether where the attributes are used in the event)
export type GA4MKPProductConfig<SubProduct extends GA4SubProducts> = {
  eventName: string;
  subProduct?: SubProduct;
  elementTitle?: string;
};

// a type that represents the event for a specific Believe Program (GA4 sub product)
// we want to be able to use this type to define the event name and the event attributes for a specific Believe Program
// i.e. Links, Track Pitch Tools, etc.
export type GA4MKPProductTrackingEvent<
  Config extends GA4MKPProductConfig<GA4SubProducts>,
> = {
  eventName: Config['eventName'];
  eventParams: GA4BaseEventParams & {
    subProduct?: Config['subProduct'];
    elementTitle?: Config['elementTitle'];
  } & Omit<Config, 'eventName'>;
};

export type GA4TrackingEvent<
  TrackingEvent extends GA4MKPProductTrackingEvent<
    GA4MKPProductConfig<GA4SubProducts>
  >,
> = {
  gaTrackers: AppGATrackers;
  eventName: TrackingEvent['eventName'];
  eventParams: TrackingEvent['eventParams'];
  sendOnlyToFirstGA4Tracker?: boolean;
};

export type TrackMenuItemClick = {
  gaTrackers: AppGATrackers;
  pathname: string;
};

export type GA4GlobalTrackingEventConfig = Omit<
  GA4TrackingEvent<
    GA4MKPProductTrackingEvent<GA4MKPProductConfig<GA4SubProducts>>
  >,
  'gaTrackers'
>;
