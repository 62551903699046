import {
  ActionTypeEnum,
  ActionScheduleTypeEnum,
} from 'shared/entities/action/action.types';
import { TargetType } from 'shared/entities/heartbeat/heartbeat.types';
import { DSP } from 'shared/entities/storeConfig/streamingPlatform.types';

/* 

For the i18next parser

t('ui.component.dsp_action_editor.action_type.ADD_ALBUM.displayName', 'Save release')
t('ui.component.dsp_action_editor.action_type.ADD_ALBUM.form_field_label', 'Release name')
t('ui.component.dsp_action_editor.action_type.ADD_TRACK.displayName', 'Like track')
t('ui.component.dsp_action_editor.action_type.ADD_TRACK.form_field_label', 'Track name')
t('ui.component.dsp_action_editor.action_type.ADD_PLAYLIST.displayName', 'Follow playlist')
t('ui.component.dsp_action_editor.action_type.ADD_PLAYLIST.form_field_label', 'Playlist name')
t('ui.component.dsp_action_editor.action_type.FOLLOW_ARTIST.displayName', 'Follow artist')
t('ui.component.dsp_action_editor.action_type.FOLLOW_ARTIST.form_field_label', 'Artist name')
t('ui.component.dsp_action_editor.action_type.FOLLOW_USER.displayName', 'Follow user')
t('ui.component.dsp_action_editor.action_type.FOLLOW_USER.form_field_label', 'User name')
*/

export const TYPE_OPTIONS = {
  [ActionTypeEnum.ADD_ALBUM]: {
    value: ActionTypeEnum.ADD_ALBUM,
    displayName: 'Save release',
    targetLabel: 'Release name',
    targetType: TargetType.ALBUM,
  },
  [ActionTypeEnum.ADD_TRACK]: {
    value: ActionTypeEnum.ADD_TRACK,
    displayName: 'Like track',
    targetLabel: 'Track name',
    targetType: TargetType.SOURCE,
  },
  [ActionTypeEnum.ADD_PLAYLIST]: {
    value: ActionTypeEnum.ADD_PLAYLIST,
    displayName: 'Follow playlist',
    targetLabel: 'Playlist name',
    targetType: TargetType.PLAYLIST,
  },
  [ActionTypeEnum.FOLLOW_ARTIST]: {
    value: ActionTypeEnum.FOLLOW_ARTIST,
    displayName: 'Follow artist',
    targetLabel: 'Artist name',
    targetType: TargetType.ARTIST,
  },
  [ActionTypeEnum.FOLLOW_USER]: {
    value: ActionTypeEnum.FOLLOW_USER,
    displayName: 'Follow user',
    targetLabel: 'User name',
    targetType: TargetType.USER,
  },
};

export const SCHEDULE_TYPE_OPTIONS = {
  [ActionScheduleTypeEnum.SCHEDULED_NOW]: {
    value: ActionScheduleTypeEnum.SCHEDULED_NOW,
    displayName: 'Instantly',
  },
  [ActionScheduleTypeEnum.SCHEDULED_ON_BACKLINK_RELEASE_DATE]: {
    value: ActionScheduleTypeEnum.SCHEDULED_ON_BACKLINK_RELEASE_DATE,
    displayName: 'On release',
  },
  [ActionScheduleTypeEnum.SCHEDULED_WITH_DATE]: {
    value: ActionScheduleTypeEnum.SCHEDULED_WITH_DATE,
    displayName: 'Custom date',
  },
};

/**
 * for i18next parser
 *
 * t('ui.component.dsp_action_editor.schedule_type.SCHEDULED_NOW.displayName', 'Instantly')
 * t('ui.component.dsp_action_editor.schedule_type.SCHEDULED_ON_BACKLINK_RELEASE_DATE.displayName', 'On release')
 * t('ui.component.dsp_action_editor.schedule_type.SCHEDULED_WITH_DATE.displayName', 'Custom date')
 */

export const PLATFORM_TYPE_OPTIONS = {
  [DSP.APPLE_MUSIC]: {
    [ActionTypeEnum.ADD_ALBUM]: true,
    [ActionTypeEnum.ADD_TRACK]: true,
    [ActionTypeEnum.ADD_PLAYLIST]: true,
    [ActionTypeEnum.FOLLOW_ARTIST]: false,
    [ActionTypeEnum.FOLLOW_USER]: false,
  },
  [DSP.SPOTIFY]: {
    [ActionTypeEnum.ADD_ALBUM]: true,
    [ActionTypeEnum.ADD_TRACK]: true,
    [ActionTypeEnum.ADD_PLAYLIST]: true,
    [ActionTypeEnum.FOLLOW_ARTIST]: true,
    [ActionTypeEnum.FOLLOW_USER]: true,
  },
  [DSP.DEEZER]: {
    [ActionTypeEnum.ADD_ALBUM]: true,
    [ActionTypeEnum.ADD_TRACK]: true,
    [ActionTypeEnum.ADD_PLAYLIST]: true,
    [ActionTypeEnum.FOLLOW_ARTIST]: true,
    [ActionTypeEnum.FOLLOW_USER]: true,
  },
};
