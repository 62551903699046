import React, { useState } from 'react';

import { MuiIcons } from '@believe-front/react-components';
import { css } from '@emotion/react';
import { debounce } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';

import ArtistCard from 'shared/components/ArtistCard';
import ArtistEditRouteSetup from 'shared/components/ArtistEditor';
import DatePickerWithTimezone from 'shared/components/DatePickerWithTimezone';
import FieldSet from 'shared/components/FieldSet';
import ProductCard from 'shared/components/ProductCard';
import { RefreshRelease } from 'shared/components/ReleaseCard/RefreshRelease';
import ReleaseEditor from 'shared/components/ReleaseEditor';
import * as StyledTypography from 'shared/components/Typography/styled';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { Release, ReleaseStore } from 'shared/entities/release/release.types';
import { useRefreshBacklink } from 'shared/hooks/entities/backlink';
import {
  useFetchRelease,
  useRefreshRelease,
  useUpdateRelease,
} from 'shared/hooks/entities/release';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { buildImageUrl } from 'shared/services/image.service';
import { getCurrentWorkspaceId, useAppContext } from 'shared/state';

import { StepKey } from '../../config';
import { getReleaseMode } from '../../state';
import { useBacklinkSettingsDataEditorContext } from '../../state/context';
import { EditorTextField } from '../fields/EditorTextField';
import { EditorStoreManager } from '../managers/EditorStoreManager';

import * as FieldsStyled from '../fields/styled';
import * as EditorStyled from '../styled';
import * as StepsStyled from './styled';

export const RELEASE_EDITOR_CONTAINER_ID = 'release-editor-container';
const DEBOUNCED_TIME_TO_REFRESH_BACKLINK = 1 * 1000;

const AddARelease = ({ releaseId }: { releaseId: string }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'ui.component.release_editor.button.add_release',
  });
  return (
    <ReleaseEditor
      releaseId={releaseId}
      OpenModalButton={
        <EditorStyled.EditorButton startIcon={<MuiIcons.AddCircleRounded />}>
          {t(
            'label',
            'Add release', // in our system we add a Product to a Release - but Products are called 'release' in the UI ...
          )}
        </EditorStyled.EditorButton>
      }
      OpenModalButtonHelpText={() => (
        <StyledTypography.HelpText>
          {t('help', 'Select both audio and video releases if available.')}
        </StyledTypography.HelpText>
      )}
    />
  );
};

export const ReleaseStep = () => {
  const { t } = useTranslation('common', {
    keyPrefix:
      'ui.component.backlink_settings_data_editor.steps.releaseStep.form',
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const routeUrl = useResolvedPath('').pathname;
  const queryClient = useQueryClient();
  const { state: appState } = useAppContext();
  const workspaceId = getCurrentWorkspaceId(appState);

  //#region Backlink
  const { stepKey, editorState, getFieldProps, parentEntityInfos } =
    useBacklinkSettingsDataEditorContext();
  const backlink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : undefined;
  const { data: artist } = useAppQuery('artist', {
    fetcherArgs: [backlink?.artist?.id as string],
    enabled: !!backlink?.artist,
  });
  const { refreshBacklink } = useRefreshBacklink();
  const debouncedRefreshBacklink = debounce(
    refreshBacklink,
    DEBOUNCED_TIME_TO_REFRESH_BACKLINK,
  );
  //#endregion

  //#region Release
  const releaseId = backlink ? backlink.releaseId : undefined;
  const { release: backlinkRelease } = useFetchRelease(releaseId);
  const { refreshRelease } = useRefreshRelease();
  const { updateRelease } = useUpdateRelease();
  const releasePath = `${stepKey}.release`;
  const { value: release, changeHandler: releaseChangeHandler } = getFieldProps(
    releasePath,
    {
      replace: true,
    },
  );
  const showOnlyIfReleaseExists = !!release && !!releaseId;
  const releaseTitlePath = `${stepKey}.release.title`;

  // if no releaseDate is defined in release we use today
  const defaultDigitalReleaseDate = moment().format('YYYY-MM-DD');

  const storesPath = `${stepKey}.release.stores`;
  const { value: editorReleaseStores, changeHandler: storesChangeHandler } =
    getFieldProps(storesPath, {
      replace: true,
    });

  const digitalReleaseDatePath = `${stepKey}.release.digitalReleaseDate`;
  const {
    value: digitalReleaseDate,
    changeHandler: digitalReleaseDateChangeHandler,
  } = getFieldProps(digitalReleaseDatePath);

  const selectedTimezonePath = `${stepKey}.release.selectedTimezone`;
  const {
    value: selectedTimezone,
    changeHandler: selectedTimezoneChangeHandler,
  } = getFieldProps(selectedTimezonePath);
  //#endregion

  const designStepPath = `${StepKey.Design}`;
  const { changeHandler: designStepChangeHandler } = getFieldProps(
    designStepPath,
    { replace: true },
  );
  const sharingStepPath = `${StepKey.Sharing}`;
  const { changeHandler: sharingStepChangeHandler } = getFieldProps(
    sharingStepPath,
    { replace: true },
  );

  const handleRemoveProduct = (internalProductId: string) => {
    if (backlinkRelease) {
      updateRelease({
        releaseId: backlinkRelease.id,
        internalProductIds: backlinkRelease.products
          .filter((product) => product.internalProductId !== internalProductId)
          .map((product) => product.internalProductId),
        workspaceId,
      });
    }
  };

  /**
   * When release si refreshed (sync from CAPI)
   * we refresh also the backlink (because backlink linked to the release)
   */
  const refreshBacklinkOnSuccess = (data: Release) => {
    if (!backlink?.id) return;

    debouncedRefreshBacklink(
      { backlinkId: backlink.id },
      {
        onSuccess: (data) => {
          if (!data) return;

          releaseChangeHandler({
            ...editorState.releaseStep.release,
            artworkUrl: data.settingsData.designStep.artworkUrl,
            digitalReleaseDate: data.digitalReleaseDate as string,
            title: data.settingsData.releaseStep.release.title,
          });
          designStepChangeHandler({
            ...editorState.designStep,
            artworkId: data.settingsData.designStep.artworkId,
            artworkUrl: data.settingsData.designStep.artworkUrl,
          });
          sharingStepChangeHandler({
            ...editorState.sharingStep,
            socialCard: {
              ...data.settingsData.sharingStep.socialCard,
              imageId: data.settingsData.sharingStep.socialCard.imageId,
              title: data.settingsData.sharingStep.socialCard.title,
              description: data.settingsData.sharingStep.socialCard.description,
            },
          });
        },
      },
    );
  };

  const refreshBacklinkSettingsReleaseInfosFromRelease = async () => {
    if (!releaseId) return;
    refreshRelease(releaseId, {
      onSuccess: ({ title, digitalReleaseDate, artworkUrl, metadata }) => {
        releaseChangeHandler({
          ...editorState.releaseStep.release,
          title,
          digitalReleaseDate: digitalReleaseDate || defaultDigitalReleaseDate,
        });
        designStepChangeHandler({
          ...editorState.designStep,
          artworkId: metadata.artworkResources?.uploadId,
          artworkUrl,
        });
        queryClient.invalidateQueries(['release', releaseId]);
      },
    });
  };

  const [storesFromRelease, setStoresFromRelease] =
    useState<Record<string, ReleaseStore>>();

  useAppQuery('release', {
    fetcherArgs: [releaseId as string],
    enabled: !!releaseId,
    onSuccess: (release) => {
      const { stores: updatedReleaseStores } = release;
      setStoresFromRelease(updatedReleaseStores);
    },
  });

  /**
   * If we switch from pre-release to post-release, we need to disable the autonotify feature
   * to avoid getting stuck in an error state (invalid notify date), blocking the user
   * from saving the backlink
   */
  const isPreRelease = getReleaseMode(editorState) === 'pre-release';
  const wasPreRelease = React.useRef(isPreRelease);
  const { changeHandler: onChangeIsAutonotifyActive } = getFieldProps(
    'prereleaseAutonotifyStep.isActive',
  );
  React.useEffect(() => {
    let timeout;
    if (wasPreRelease.current !== isPreRelease && !isPreRelease) {
      wasPreRelease.current = isPreRelease;
      /**
       * If we switch off the autonotify too fast, a false validation error is triggered
       * on the invalid autonotify date, even if the feature is now off.
       * Users get stuck with the editor in an error state and have to change _something_
       * to make the error go away.
       * TODO investigate later
       */
      timeout = setTimeout(() => onChangeIsAutonotifyActive(false), 1000);
    }
    return () => timeout && clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreRelease]);

  return (
    <>
      <StepsStyled.StepContent>
        {showOnlyIfReleaseExists && (
          <>
            <FieldSet
              title={t(
                'fieldset.promote_release.title',
                'Which release are you promoting today?',
              )}
              hideTooltip
              variant="condensed"
              actions={
                releaseId && (
                  <RefreshRelease
                    releaseId={releaseId}
                    onSuccess={refreshBacklinkOnSuccess}
                    icon={<MuiIcons.Refresh />}
                  />
                )
              }
            >
              <div id={RELEASE_EDITOR_CONTAINER_ID}>
                {/* list backlink's releases */}
                {backlinkRelease?.products.map((product) => (
                  <ProductCard
                    key={product.internalProductId}
                    product={product}
                    preTitle={
                      <IconButton
                        onClick={() =>
                          handleRemoveProduct(product.internalProductId)
                        }
                        size="large"
                      >
                        <MuiIcons.RemoveCircle />
                      </IconButton>
                    }
                    css={(theme) => css`
                      margin-bottom: ${theme.spacing(0.5)};
                      &:last-of-type {
                        margin-bottom: 0;
                      }
                    `}
                  />
                ))}
                {/* add a new release to backlink */}
                <AddARelease releaseId={releaseId} />
              </div>
            </FieldSet>
            <FieldSet
              title={t('fieldset.release_fields.title', 'Looks good?')}
              variant="condensed"
            >
              <FieldsStyled.FieldRow>
                <EditorTextField
                  label={t('field.release_name.label', 'Release name')}
                  name={releaseTitlePath}
                  {...getFieldProps(releaseTitlePath)}
                />
              </FieldsStyled.FieldRow>
              {!!artist && (
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    css={{
                      color: theme.palette.grey[600],
                      marginBottom: '0.5rem',
                    }}
                  >
                    {t('card.release_artist.label', 'Release artist')}
                  </Typography>
                  <EditorStyled.Card
                    isExpanded={false}
                    aria-label={t('card.release_artist.label')}
                  >
                    <EditorStyled.CardHeader
                      disableTypography
                      title={
                        <div css={{ '* span': { fontWeight: 'normal' } }}>
                          <ArtistCard
                            name={artist.name}
                            thumbnailSize={30}
                            picture={
                              !!artist.data.profilePictureId
                                ? buildImageUrl({
                                    imageId: artist.data.profilePictureId,
                                  })
                                : ''
                            }
                          />
                        </div>
                      }
                      action={
                        <IconButton
                          onClick={() =>
                            navigate(`${routeUrl}/artist/${artist.id}/edit`)
                          }
                          aria-label={t(
                            'card.release_artist.action.edit_artist',
                            'Edit artist',
                          )}
                          size="large"
                        >
                          <MuiIcons.Edit />
                        </IconButton>
                      }
                    />
                  </EditorStyled.Card>
                  <ArtistEditRouteSetup />
                </div>
              )}
              <FieldsStyled.FieldRow>
                <DatePickerWithTimezone
                  dateLabel={t('field.release_date.label', 'Release day')}
                  dateValue={digitalReleaseDate || defaultDigitalReleaseDate}
                  dateChangeHandler={digitalReleaseDateChangeHandler}
                  timezoneValue={selectedTimezone}
                  timezoneChangeHandler={selectedTimezoneChangeHandler}
                />
              </FieldsStyled.FieldRow>
              <StyledTypography.HelpText>
                {t(
                  'field.release_date.help',
                  'On this day, your link turns from a pre-save link to a smart link.',
                )}
              </StyledTypography.HelpText>
            </FieldSet>
          </>
        )}
        <>
          <FieldSet
            css={css`
              border-bottom: 'none';
            `}
            hideTooltip
            title={t(
              'fieldset.release_stores.title',
              'Where can music fans enjoy your release?',
            )}
            variant="condensed"
            actions={
              <Tooltip
                title={
                  t(
                    'fieldset.release_fields.tooltip',
                    'Rescan and refresh store URLs',
                  ) as string
                }
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={refreshBacklinkSettingsReleaseInfosFromRelease}
                >
                  <MuiIcons.Refresh />
                </IconButton>
              </Tooltip>
            }
          >
            <EditorStoreManager
              value={editorReleaseStores}
              storesFromRelease={storesFromRelease}
              path={storesPath}
              changeHandler={storesChangeHandler}
              editorState={editorState}
              releaseId={releaseId}
            />
          </FieldSet>
        </>
      </StepsStyled.StepContent>
    </>
  );
};
