import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import produce from 'immer';
import debounce from 'lodash/debounce';
import { Trans, useTranslation } from 'react-i18next';

import AddCircle from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Box,
  IconButton,
  CardContent,
  Collapse,
  CardProps,
  useTheme,
  Button,
  Popper,
  TextField,
  ButtonBase,
  Link,
  Tooltip,
  Stack,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useBacklinkSettingsDataEditorContext } from 'shared/components/BacklinkSettingsDataEditor/state/context';
import { FlagWithLabel } from 'shared/components/FlagWithLabel';
import PlatformUrlField from 'shared/components/PlatformUrlField';
import * as StyledTypography from 'shared/components/Typography/styled';
import { BacklinkReleaseMode } from 'shared/entities/backlink/backlink.types';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { TargetType } from 'shared/entities/heartbeat/heartbeat.types';
import { RELEASE_LINK_DEFAULT_KEY } from 'shared/entities/release/release.constants';
import {
  BacklinkReleaseStore,
  ReleaseStore,
} from 'shared/entities/release/release.types';
import { StoreConfig } from 'shared/entities/storeConfig/storeConfig.types';
import { DSP } from 'shared/entities/storeConfig/streamingPlatform.types';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { buildStoreLogoLabelUri } from 'shared/services/assets.service';
import { scrollElementIntoViewById } from 'shared/utils/dom';

import EditorUrlTextField from '../../../fields/EditorUrlTextField';
import { RELEASE_EDITOR_CONTAINER_ID } from '../../../steps';
import { EditorFieldProps } from '../../../types';
import { getStoreCardMetas } from '../utils/storeUrlsValidation';

import * as EditorStyled from '../../../styled';
import * as Styled from './styled';

/****************************************************************************/
// STORE CARD
/****************************************************************************/
export type StoreCardProps = EditorFieldProps<BacklinkReleaseStore> &
  CardProps & {
    name: string;
    releaseValue: ReleaseStore | undefined;
    store: StoreConfig;
    isExpanded: boolean;
    errors: {
      [iso: string]: { url: string | boolean };
    };
    changeHandler: (store: BacklinkReleaseStore) => void;
    onRemoveStore: () => void;
    onExpand: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    backlinkReleaseMode: BacklinkReleaseMode;
    releaseId?: string;
  };

const StoreCard = (props: StoreCardProps) => {
  const { t } = useTranslation();

  const {
    name,
    value: releaseStore,
    releaseValue: releaseStoreFromRelease,
    store,
    backlinkReleaseMode,
    isExpanded,
    onExpand,
    errors,
    onRemoveStore,
    releaseId,
    ...otherProps
  } = props;

  const hasErrors =
    Object.values(errors).some((err) => Boolean(err.url)) &&
    !releaseStore.useAutoscan;
  const [hasErrorInDspLinks, setHasErrorInDspLinks] = React.useState(false);

  const { displayName, canUseAutoscan } = store;
  const labelImageUri = buildStoreLogoLabelUri(name);

  // allow editing store links only in a Backlink context
  const { parentEntityInfos } = useBacklinkSettingsDataEditorContext();
  const allowEdit = parentEntityIsBacklink(parentEntityInfos);

  const theme = useTheme();

  const { data: release } = useAppQuery('release', {
    fetcherArgs: [releaseId as string],
    enabled: !!releaseId,
  });

  const {
    noProductsOfMatchingTypeInRelease,
    headerStatus,
    headerLabel,
    fallbackUrl,
  } = getStoreCardMetas({
    releaseStore,
    release,
    storeConfig: store,
    releaseStoreFromRelease,
    backlinkReleaseMode,
    hasErrorInDspLinks,
    errors,
  });

  return (
    <EditorStyled.Card
      isExpanded={isExpanded}
      hasErrors={hasErrors}
      role={otherProps.role}
      aria-label={otherProps['aria-label']}
      aria-expanded={isExpanded}
      aria-invalid={hasErrors}
    >
      <EditorStyled.CardHeader
        css={{
          backgroundColor:
            !isExpanded && ['error', 'warning'].includes(headerStatus)
              ? theme.palette[headerStatus].light
              : 'initial',
          cursor: 'pointer',
        }}
        onClick={(event) => {
          onExpand(event, !isExpanded);
        }}
        disableTypography
        title={
          <>
            <Tooltip
              title={`${t('ui.button.remove', 'Remove')} ${displayName}`}
            >
              <IconButton
                aria-label={`${t('ui.button.remove', 'Remove')} ${displayName}`}
                onClick={(event) => {
                  event.stopPropagation();
                  onRemoveStore();
                }}
                css={css`
                  margin-right: 6px;
                `}
                size="large"
              >
                <RemoveCircleIcon htmlColor={theme.palette.grey[600]} />
              </IconButton>
            </Tooltip>
            <img src={labelImageUri} alt={store.displayName} />
          </>
        }
        action={
          allowEdit ? (
            <>
              <Styled.HeaderLinkInfoContainer $color={headerStatus}>
                <span>{headerLabel}</span>
                {headerStatus === 'error' ? (
                  <ErrorIcon
                    css={(theme) => css`
                      && {
                        color: ${theme.palette.error.main};
                      }
                    `}
                  />
                ) : headerStatus === 'warning' ? (
                  <ErrorIcon
                    css={(theme) => css`
                      && {
                        color: ${theme.palette.warning.main};
                      }
                    `}
                  />
                ) : (
                  <CheckCircleIcon
                    css={(theme) => css`
                      && {
                        color: ${theme.palette.success.main};
                      }
                    `}
                  />
                )}
              </Styled.HeaderLinkInfoContainer>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onExpand(event, !isExpanded);
                }}
                size="small"
                aria-label={
                  isExpanded
                    ? t('ui.button.close', 'Close')
                    : t('ui.button.open', 'Open')
                }
              >
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </>
          ) : Boolean(releaseStore.useAutoscan) ? (
            <div aria-label="store infos">
              <FlashOnIcon />{' '}
              {t(
                'ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.title',
                'Auto-scan',
              )}
            </div>
          ) : null
        }
      />
      <Collapse in={isExpanded} timeout={100} unmountOnExit>
        <CardContent>
          <Stack aria-label="store content" direction="column" spacing={1}>
            {canUseAutoscan && (
              <AutoScanToggle hasErrors={hasErrors} {...props} />
            )}

            {releaseStore.useAutoscan ? (
              <StyledTypography.HelpText>
                {t(
                  'ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.help.verify',
                  'Auto-scan will automatically verify if an URL is available on release.',
                )}
              </StyledTypography.HelpText>
            ) : !releaseStoreFromRelease?.url &&
              backlinkReleaseMode === 'post-release' &&
              fallbackUrl &&
              headerStatus !== 'success' ? (
              <EditorStyled.WarningMessage icon={<ErrorIcon />}>
                <Trans i18nKey="ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.help.unavailable_for_store_with_infos">
                  Please add the release URL but don’t worry, fans are
                  redirected to a{' '}
                  <a
                    href={fallbackUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    temporary URL
                  </a>{' '}
                  in the meantime
                </Trans>
              </EditorStyled.WarningMessage>
            ) : !releaseStoreFromRelease?.url && headerStatus !== 'success' ? (
              <EditorStyled.WarningMessage icon={<ErrorIcon />}>
                {t(
                  'ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.help.unavailable_for_store',
                  'Please add your url manually.',
                )}
              </EditorStyled.WarningMessage>
            ) : null}
            {noProductsOfMatchingTypeInRelease &&
              backlinkReleaseMode === 'pre-release' && (
                <EditorStyled.WarningMessage icon={<ErrorIcon />}>
                  <>
                    {t(
                      'ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.help.wont_work_unless',
                      'Auto-scan won’t work unless you',
                    )}{' '}
                    <Link
                      onClick={() =>
                        scrollElementIntoViewById(RELEASE_EDITOR_CONTAINER_ID)
                      }
                      css={{ cursor: 'pointer' }}
                    >
                      {store.name === 'youtube'
                        ? t(
                            'ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.help.select_video_release',
                            'select a video release',
                          )
                        : t(
                            'ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.help.select_audio_release',
                            'select an audio release',
                          )}
                    </Link>
                  </>
                </EditorStyled.WarningMessage>
              )}
            {releaseStore.useAutoscan &&
            !releaseStoreFromRelease?.url &&
            backlinkReleaseMode === 'post-release' ? (
              <EditorStyled.WarningMessage icon={<ErrorIcon />}>
                {fallbackUrl ? (
                  <Trans i18nKey="ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.help.release_url_not_found_with_fallback">
                    We have not found the release URL yet. Please add it
                    manually but don't worry, fans are redirected to a{' '}
                    <a
                      href={fallbackUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      temporary URL
                    </a>{' '}
                    in the meantime
                  </Trans>
                ) : (
                  t(
                    'ui.component.backlink_settings_data_editor.store_card.feature.auto_scan.help.release_url_not_found',
                    'The release URL was not found automatically. Switch to Manual to insert your release URL.',
                  )
                )}
              </EditorStyled.WarningMessage>
            ) : null}
            <StoreLinkInput
              setHasErrorInDspLinks={setHasErrorInDspLinks}
              {...props}
            />
          </Stack>
        </CardContent>
      </Collapse>
    </EditorStyled.Card>
  );
};

export default StoreCard;

/****************************************************************************/
// AUTO SCAN TOGGLE
/****************************************************************************/
const AutoScanToggle = ({
  hasErrors,
  value: releaseStore,
  changeHandler,
}: StoreCardProps & { hasErrors: boolean }) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      exclusive
      aria-label={t(
        'ui.component.backlink_settings_data_editor.store_card.auto_scan_toggle.label',
        'Autoscan Or Manual url',
      )}
      onChange={(event: React.MouseEvent<HTMLElement>, value: boolean) => {
        if (hasErrors) {
          return;
        }
        const updatedStore = {
          ...releaseStore,
          useAutoscan: value,
          links: value
            ? // When using autoscan we do not have target id, so we remove it from the links
              Object.entries(releaseStore.links).reduce(
                (links, [iso, { url }]) => ({ ...links, [iso]: { url } }),
                {},
              )
            : releaseStore.links,
        };
        changeHandler(updatedStore);
      }}
      css={{
        width: '100%',
      }}
    >
      <Styled.ToggleButton
        selected={releaseStore.useAutoscan}
        value={true}
        aria-label={t(
          'ui.component.backlink_settings_data_editor.store_card.auto_scan_toggle.option.auto_scan',
          'Auto-scan',
        )}
      >
        {t(
          'ui.component.backlink_settings_data_editor.store_card.auto_scan_toggle.option.auto_scan_url',
          'Autoscan URL',
        )}
      </Styled.ToggleButton>
      <Styled.ToggleButton
        selected={!releaseStore.useAutoscan}
        value={false}
        aria-label={t(
          'ui.component.backlink_settings_data_editor.store_card.auto_scan_toggle.option.manual',
          'Manual',
        )}
      >
        {t(
          'ui.component.backlink_settings_data_editor.store_card.auto_scan_toggle.option.add_urls_manually',
          'Add URLs manually',
        )}
      </Styled.ToggleButton>
    </ToggleButtonGroup>
  );
};

/****************************************************************************/
// STORE LINK INPUT
/****************************************************************************/
const StoreLinkInput = (
  props: StoreCardProps & {
    setHasErrorInDspLinks: React.Dispatch<React.SetStateAction<boolean>>;
  },
) => {
  const { t } = useTranslation();

  const {
    name,
    value: releaseStore,
    releaseValue: releaseStoreFromRelease,
    changeHandler,
    errors,
    setHasErrorInDspLinks,
  } = props;
  const isDSP = Object.values(DSP).includes(name as DSP);

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 100),
    [changeHandler],
  );

  return (
    <div>
      {releaseStore.useAutoscan ? (
        <EditorUrlTextField
          inputProps={{
            'aria-label': t(
              'ui.component.backlink_settings_data_editor.store_card.store_link.auto_scan_link.label',
              '{{name}} autoscan link',
              { name },
            ),
          }}
          name={name}
          value={releaseStoreFromRelease?.url || ''}
          changeHandler={(value) => {}}
          disabled
        />
      ) : (
        Object.entries(releaseStore.links).map(([iso, link]) => (
          <CountryLinkWrapper key={iso} countryCode={iso} {...props}>
            {isDSP ? (
              <PlatformUrlField
                inputId={`${name}-${iso}-link`}
                inputProps={{
                  'aria-label': t(
                    'ui.component.backlink_settings_data_editor.store_card.store_link.label',
                    '{{name}} {{iso}} link',
                    { name, iso },
                  ),
                }}
                placeholder={t(
                  'ui.component.backlink_settings_data_editor.store_card.store_link.placeholder',
                  'Insert link',
                )}
                noLabel
                resourceId={link.id}
                resourceUrl={link.url}
                onChange={(value) => {
                  debouncedChangeHandler({
                    ...releaseStore,
                    links: {
                      ...releaseStore.links,
                      [iso]: { url: value },
                    },
                  });
                }}
                onUrlResolved={({ resourceId, resourceUrl }) => {
                  setHasErrorInDspLinks(false);
                  debouncedChangeHandler({
                    ...releaseStore,
                    links: {
                      ...releaseStore.links,
                      [iso]: { id: resourceId, url: resourceUrl },
                    },
                  });
                }}
                onUrlNotResolved={() => {
                  setHasErrorInDspLinks(true);
                }}
                platformName={name as DSP}
                targetType={TargetType.ALBUM}
              />
            ) : (
              <EditorUrlTextField
                inputProps={{
                  'aria-label': t(
                    'ui.component.backlink_settings_data_editor.store_card.store_link.label',
                    '{{name}} {{iso}} link',
                    { name, iso },
                  ),
                }}
                placeholder={t(
                  'ui.component.backlink_settings_data_editor.store_card.store_link.placeholder',
                  'Insert link',
                )}
                name={name}
                value={link.url || ''}
                changeHandler={(value) => {
                  debouncedChangeHandler({
                    ...releaseStore,
                    links: {
                      ...releaseStore.links,
                      [iso]: { url: value },
                    },
                  });
                }}
                error={Boolean(errors[iso]?.url)}
              />
            )}
          </CountryLinkWrapper>
        ))
      )}
    </div>
  );
};

/****************************************************************************/
// COUNTRY LINK INPUT WRAPPER
/****************************************************************************/
const countries = Object.entries(countriesEN.countries).map(([code, name]) => ({
  code,
  name: Array.isArray(name) ? name.join(', ') : name,
}));

type CountryLinkWrapperProps = StoreCardProps & {
  countryCode: string;
  children: React.ReactNode;
};

const CountryLinkWrapper = ({
  name: storeName,
  value: releaseStore,
  countryCode,
  changeHandler,
  children: input,
}: CountryLinkWrapperProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDefaultLink = countryCode === RELEASE_LINK_DEFAULT_KEY;

  function handleAddCountry(isoCode: string | null) {
    if (!isoCode) return;
    // Insert new link after the default (and first) one
    const storeLinks = produce(Object.entries(releaseStore.links), (draft) => {
      draft.splice(1, 0, [
        isoCode,
        { url: releaseStore.links[RELEASE_LINK_DEFAULT_KEY].url },
      ]);
    });
    changeHandler({
      ...releaseStore,
      links: Object.fromEntries(storeLinks),
    });
  }

  function handleRemoveCountry() {
    const updatedStore = {
      ...releaseStore,
      links: produce(releaseStore.links, (draft) => {
        delete draft[countryCode];
      }),
    };
    changeHandler(updatedStore);
  }

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 16,
      }}
    >
      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label htmlFor={`${storeName}-${countryCode}-link`}>
          <Box sx={{ margin: '8px' }}>
            {isDefaultLink ? (
              <FlagWithLabel locale="default">
                {t(
                  'ui.component.backlink_settings_data_editor.store_card.store_link.default',
                  'Default',
                )}
              </FlagWithLabel>
            ) : (
              <FlagWithLabel locale={countryCode}>{countryCode}</FlagWithLabel>
            )}
          </Box>
        </label>
        {isDefaultLink ? (
          <CountrySelector handleAddCountry={handleAddCountry} />
        ) : (
          <ButtonBase
            aria-label={t(
              'ui.component.backlink_settings_data_editor.store_card.store_link.button.remove.a11y.label',
              'Remove {{countryCode}} link',
              { countryCode },
            )}
            css={{
              color: theme.palette.grey[600],
              fontWeight: 600,
              fontSize: '0.9rem',
            }}
            onClick={handleRemoveCountry}
          >
            {t(
              'ui.component.backlink_settings_data_editor.store_card.store_link.button.remove.label',
              'Remove',
            )}
          </ButtonBase>
        )}
      </div>
      {input}
    </div>
  );
};

/****************************************************************************/
// COUNTRY SELECTOR (POPPER + AUTO-COMPLETE)
/****************************************************************************/
type CountrySelectorProps = {
  handleAddCountry: (countryCode: string | null) => void;
};

const CountrySelector = ({ handleAddCountry }: CountrySelectorProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const theme = useTheme();

  const isOpen = Boolean(anchorEl);
  const popperId = isOpen ? 'country-selector' : undefined;

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event, reason) {
    if (reason === 'toggleInput') return;
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Button
        variant="text"
        endIcon={<AddCircle />}
        color="primary"
        onClick={handleClick}
        sx={{ mr: -1, '&:hover': { backgroundColor: 'transparent' } }}
      >
        {t(
          'ui.component.backlink_settings_data_editor.store_card.country_selector.title',
          'Add Territory',
        )}
      </Button>
      <Popper
        id={popperId}
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        css={{
          backgroundColor: theme.palette.common.white,
          boxShadow: '0 3px 12px rgba(27,31,35,.15)',
          borderRadius: 3,
          width: 300,
          // This needs to get on top of the main editor container (which is 1300)
          zIndex: 2000,
        }}
      >
        <Autocomplete
          open
          onClose={handleClose}
          onChange={(event, newValue) =>
            handleAddCountry(newValue?.code || null)
          }
          disablePortal
          options={countries}
          getOptionLabel={(option) => option.name}
          noOptionsText={t(
            'ui.component.backlink_settings_data_editor.store_card.country_selector.search.no_results',
            'No territories matching your query',
          )}
          renderTags={() => null}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                'ui.component.backlink_settings_data_editor.store_card.country_selector.label',
                'Territory',
              )}
              variant="outlined"
              autoFocus
              fullWidth
            />
          )}
          renderOption={(props, { code, name }) => (
            <li {...props}>
              <FlagWithLabel locale={code} />
            </li>
          )}
        />
      </Popper>
    </React.Fragment>
  );
};
