import { api } from '../api';
import { EnginePoolKPIs } from './action.api.types';
import { Action, ActionSub, ActionTriggerStatus } from './action.types';

export const fetchActions = async (
  params: {
    backlinkId?: string;
    withSubsKPI?: boolean;
    page: number;
    limit: number;
  } = {
    page: 0,
    limit: 20,
  },
): Promise<{ results: Action[]; totalCount: number }> => {
  const results = await api.get('/actions', { params });
  return results.data;
};

export const fetchAction = async (id: string): Promise<Action> => {
  const results = await api.get(`/actions/${id}`);
  return results.data;
};

export const retryAllFailedActionSub = async (
  actionId: string,
): Promise<Action> => {
  const results = await api.post(`/actions/${actionId}/retryAllFailed`);
  return results.data;
};

export const fetchActionSubs = async (
  params: {
    actionId?: string;
    activeTriggerStatus?: ActionTriggerStatus;
    withFanInfos?: boolean;
    withActiveTrigger?: boolean;
    page: number;
    limit: number;
  } = {
    withFanInfos: false,
    withActiveTrigger: false,
    page: 0,
    limit: 20,
  },
): Promise<{ results: ActionSub[]; totalCount: number }> => {
  const {
    page,
    limit,
    actionId,
    activeTriggerStatus,
    withFanInfos,
    withActiveTrigger,
  } = params;
  const results = await api.get(`/actions/${actionId}/subs`, {
    params: {
      activeTriggerStatus,
      withFanInfos,
      withActiveTrigger,
      page,
      limit,
    },
  });
  return results.data;
};

export async function fetchEnginePoolKPIs(): Promise<EnginePoolKPIs> {
  const results = await api.get('/actions/enginePool/locked');
  return results.data;
}

export async function purgeActions(): Promise<void> {
  await api.post('/actions/enginePool/locked/purge');
}
