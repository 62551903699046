import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, Grid } from '@mui/material';

import ArtistEditRouteSetup from 'shared/components/ArtistEditor/ArtistEditor';
import { HIGHLIGHT_DOM_IDS } from 'shared/components/ArtistSettingsPanel/tabs/Profile';
import { SlugValidationInfos } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useAppQuery } from 'shared/hooks/useAppQuery';

import { FieldInfos } from '../styled';

type Props = {
  validationInfos: SlugValidationInfos;
  artistId?: string;
  onArtistPanelOpen: () => void;
  onArtistPanelClose: () => void;
};

const SlugValidationMessage = ({
  validationInfos,
  artistId,
  onArtistPanelOpen,
  onArtistPanelClose,
}: Props) => {
  const slug = validationInfos.query.slug;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routeUrl = useResolvedPath('').pathname;
  const { data: artist } = useAppQuery('artist', {
    fetcherArgs: [artistId || ''],
    enabled: !!artistId,
  });

  if (!validationInfos.isAvailable) {
    if (!validationInfos.subDomainConfigured) {
      return (
        <>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <FieldInfos color="info" importantColor="error">
                <WarningIcon className="important icon" fontSize="small" />
                <Trans i18nKey="ui.component.backlink_settings_data_editor.slug_validation.subDomain.not_configured">
                  {{ slug }} is <span className="important">not available</span>
                  . Configure a subdomain in the artist settings to use this
                  page name.
                </Trans>
              </FieldInfos>
            </Grid>
            <Grid item>
              {artist && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onArtistPanelOpen();
                    navigate(
                      `${routeUrl}/artist/${artist.id}/edit?highlight=${HIGHLIGHT_DOM_IDS.ARTIST_SUBDOMAIN}`,
                    );
                  }}
                  aria-label={t(
                    'ui.component.backlink_settings_data_editor.slug_validation.subDomain.configure',
                    'Configure Subdomain',
                  )}
                >
                  Configure subdomain
                </Button>
              )}
            </Grid>
          </Grid>
          <ArtistEditRouteSetup onArtistPanelClose={onArtistPanelClose} />
        </>
      );
    }
    if (!validationInfos.subDomainSelected) {
      return (
        <FieldInfos color="info" importantColor="error">
          <WarningIcon className="important icon" fontSize="small" />
          <Trans i18nKey="ui.component.backlink_settings_data_editor.slug_validation.subDomain.not_selected">
            {{ slug }} is <span className="important">not available</span>.
            Select the artist subdomain to use this page name.
          </Trans>
        </FieldInfos>
      );
    }
    return (
      <FieldInfos color="info" importantColor="error">
        <WarningIcon className="important icon" fontSize="small" />
        <Trans i18nKey="ui.component.backlink_settings_data_editor.slug_validation.unavailable">
          {{ slug }} is <span className="important">not available</span>
        </Trans>
      </FieldInfos>
    );
  }
  if (!validationInfos.isValid) {
    return (
      <FieldInfos color="info" importantColor="error">
        <WarningIcon className="important icon" fontSize="small" />
        <Trans i18nKey="ui.component.backlink_settings_data_editor.slug_validation.invalid">
          {{ slug }} is <span className="important">not valid</span>. Please use
          more than 3 alphanumeric characters separated by the dash character
          (ex: this-is-a-great-release).
        </Trans>
      </FieldInfos>
    );
  }
  if (validationInfos.isAvailable) {
    return (
      <FieldInfos color="info" importantColor="success">
        <CheckIcon className="important icon" fontSize="small" />
        <Trans i18nKey="ui.component.backlink_settings_data_editor.slug_validation.available">
          {{ slug }} is <span className="important">available</span>
        </Trans>
      </FieldInfos>
    );
  }

  return null;
};

export default SlugValidationMessage;
