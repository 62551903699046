import { getArtistFromLocalStorage } from 'shared/entities/artist/mocks/fixtures/artist.fixture.all';
import { backlinkBacklinkSettings } from 'shared/entities/backlinkSettings/mocks/fixtures/backlinkSettings.fixture.byParentEntity';
import { transformBacklinkSettingsDataV1ToV2 } from 'shared/entities/backlinkSettings/mocks/fixtures/backlinkSettings.fixture.helpers';

import { Backlink } from '../../backlink.types';

export const backlink: Backlink = {
  id: '1527bdb4-5a4d-4865-8322-58942e4bee3c',
  createdAt: '2021-06-16T09:55:54.647Z',
  updatedAt: '2021-06-16T09:55:54.647Z',
  slug: 'an-aural-symbiotic-mystery',
  subDomain: '',
  digitalReleaseDate:
    backlinkBacklinkSettings.data.releaseStep.release.digitalReleaseDate,
  releaseId: 'f28bee5a-2100-4412-a6ab-bf3b2e874ad7',
  workspaceId: 'c955404a-18a1-44c1-b020-48e0cce04533',
  autonotifyId: 'a0502115-bd28-4829-bf8e-06dcd795ffb6',
  isArchived: false,
  settingsData: backlinkBacklinkSettings.data,
  settingsDataV2: transformBacklinkSettingsDataV1ToV2(
    backlinkBacklinkSettings.data,
  ),
  artist: getArtistFromLocalStorage('8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea'),
  analytics: {
    visited: 96,
    clickedToStore: 45,
    optins: 10,
    preSaved: 8,
    lastUpdate: '2020-06-16T09:55:54.647Z',
  },
};

export const refreshedBacklink: Backlink = {
  ...backlink,
  digitalReleaseDate: '2022-12-30T22:00:00.000Z',
  settingsData: {
    ...backlink.settingsData,
    designStep: {
      ...backlink.settingsData.designStep,
      artworkUrl: 'https://covers.believedigital.com/140/3661585593294.jpg',
    },
    releaseStep: {
      ...backlink.settingsData.releaseStep,
      release: {
        ...backlink.settingsData.releaseStep.release,
        title: 'An awesome new title',
        digitalReleaseDate: '2022-12-30T22:00:00.000Z',
      },
    },
    sharingStep: {
      ...backlink.settingsData.sharingStep,
      socialCard: {
        ...backlink.settingsData.sharingStep.socialCard,
        imageId: 'newImageId',
        title: 'Charlemagne Palestine — An awesome new title',
        description: 'Sortie le December 30, 2022',
      },
    },
  },
};

export default backlink;
