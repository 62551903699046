export const BACKLINKS_PER_PAGE_DEFAULT = 10;

export const BACKLINK_OPTIN_COMPETITION_MIN_AGE = 15;
export const BACKLINK_OPTIN_COMPETITION_MAX_AGE = 99;
export const BACKLINK_OPTIN_COMPETITION_AGES = [
  ...new Array(
    BACKLINK_OPTIN_COMPETITION_MAX_AGE - BACKLINK_OPTIN_COMPETITION_MIN_AGE + 1,
  ),
].map((_, index) => index + BACKLINK_OPTIN_COMPETITION_MIN_AGE);
export const BACKLINK_OPTIN_COMPETITION_DEFAULT_WINNERS_COUNT = 3;

export const VALID_SUBDOMAIN_REGEXP =
  /^(?:(?![()@:%_+.~#?&//=!$&*,;'[\]\s]).)+$/u;

export const VALID_SLUG_REGEXP = /^[^()@:%_+.~#?&//=!;'[\]\s]{1,}$/u;
