import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import { css } from '@emotion/react';
import debounce from 'lodash/debounce';

import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import { resolveUrl } from 'shared/entities/heartbeat/heartbeat.api';
import { TargetType } from 'shared/entities/heartbeat/heartbeat.types';
import {
  PlatformInfos,
  DSP,
} from 'shared/entities/storeConfig/streamingPlatform.types';
import i18n from 'shared/i18n';

const styles = {
  textField: css`
    width: 100%;
  `,
};

const getErrorByTypes = () => ({
  [TargetType.ALBUM]: i18n.t(
    'ui.component.platform_url_field.error.type.album',
    'album, single or EP',
  ),
  [TargetType.SOURCE]: i18n.t(
    'ui.component.platform_url_field.error.type.track',
    'track',
  ),
  [TargetType.ARTIST]: i18n.t(
    'ui.component.platform_url_field.error.type.artist',
    'artist',
  ),
  [TargetType.USER]: i18n.t(
    'ui.component.platform_url_field.error.type.profile',
    'profile',
  ),
  [TargetType.PLAYLIST]: i18n.t(
    'ui.component.platform_url_field.error.type.playlist',
    'playlist',
  ),
});

type Props = {
  resourceId?: string;
  resourceUrl?: string;
  onChange?: (value: string) => void;
  onUrlResolved: ({ resourceId, resourceUrl }) => void;
  onUrlCleared?: () => void;
  onUrlNotResolved?: () => void;
  onUrlIsBeingChecked?: (value: boolean) => void;
  platformName: DSP;
  targetType: TargetType;
  placeholder?: string;
  noLabel?: boolean;
  customLabel?: string;
  inputId?: string;
  inputProps?: InputBaseComponentProps;
  additionnalError?: string;
};

const PlatformUrlField = ({
  resourceUrl,
  resourceId,
  onUrlResolved,
  onUrlCleared = () => {},
  onUrlNotResolved = () => {},
  onUrlIsBeingChecked = (value) => {},
  onChange = (value) => {},
  platformName,
  targetType,
  placeholder,
  noLabel,
  customLabel,
  inputId,
  inputProps,
  additionnalError,
}: Props) => {
  const [url, setUrl] = useState(resourceUrl || '');
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  /**
   * we need a way to control whether we're going to signal the result of the URL check on the server
   * primary use case is the case when we clear the url field and a check has already been started for a previous field value
   * but the server response has not been received yet
   * when it is received we want to prevent setting a new url in the upper state, which would override the '' string that we just set
   * this would happen because the onUrlResolved handler triggers the url change in the upper state
   *
   */
  const shouldSignalUrlIsResolvedRef = useRef(true);

  useEffect(() => {
    return () => {
      shouldSignalUrlIsResolvedRef.current = false;
    };
  }, []);

  const checkUrlIsValid = useCallback(
    async (url: string) => {
      if (!shouldSignalUrlIsResolvedRef.current || isFetching) return;
      setIsFetching(true);
      onUrlIsBeingChecked(true);
      try {
        const response = await resolveUrl(url);
        if (!shouldSignalUrlIsResolvedRef.current) return;
        if (response) {
          if (
            targetType !== response.type ||
            platformName !== response.platform
          ) {
            setError(
              i18n.t(
                'ui.component.platform_url_field.error.invalid_url',
                'Paste a valid {{type}} link.',
                { type: getErrorByTypes()[targetType] },
              ),
            );
            onUrlNotResolved();
          } else {
            if (shouldSignalUrlIsResolvedRef.current) {
              onUrlResolved({ resourceUrl: url, resourceId: response.id });
              setError('');
            }
          }
        }
      } catch (error: any) {
        setError(
          i18n.t(
            'ui.component.platform_url_field.error.invalid_url',
            'Paste a valid {{type}} link.',
            { type: getErrorByTypes()[targetType] },
          ),
        );
        onUrlNotResolved();
      } finally {
        onUrlIsBeingChecked(false);
      }
      setIsFetching(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [platformName, targetType],
  );

  const debouncedCheckUrlIsValid = useMemo(
    () => debounce(checkUrlIsValid, 1000),
    [checkUrlIsValid],
  );

  useEffect(() => {
    if (url) debouncedCheckUrlIsValid(url);
  }, [url, debouncedCheckUrlIsValid]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    shouldSignalUrlIsResolvedRef.current = true;
    const value = event.target.value;
    setUrl(value);
    if (value === '') {
      shouldSignalUrlIsResolvedRef.current = false;
      onUrlCleared();
    }
    onChange(value);
  };

  return (
    <div>
      <TextField
        id={inputId ?? `${platformName}-link`}
        css={styles.textField}
        label={
          !noLabel
            ? customLabel || `${PlatformInfos[platformName].displayName} link`
            : undefined
        }
        placeholder={placeholder}
        variant="outlined"
        value={url}
        onChange={handleChange}
        error={!!error || !!additionnalError}
        helperText={error || additionnalError}
        inputProps={inputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                href={url}
                target="_blank"
                disabled={!url}
                size="large"
              >
                <LaunchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default PlatformUrlField;
