import {
  Action,
  ActionTypeEnum,
  ActionScheduleTypeEnum,
} from '../../action.types';

export const action: Action = {
  id: '7aa9b690-2e48-48c7-b80f-05924a61c479',
  createdAt: '2021-06-23T08:45:31.171Z',
  updatedAt: '2021-06-23T09:49:11.264Z',
  artistId: '8db27ac3-4d7c-4d21-a2b2-5b03e11d83ea',
  targetName: 'An aural symbiotic mystery',
  type: ActionTypeEnum.ADD_ALBUM,
  scheduleType: ActionScheduleTypeEnum.SCHEDULED_WITH_DATE,
  schedulableOn: '2021-06-23T22:00:00.000Z',
  config: {
    platforms: {
      spotify: {
        targetUrl: 'https://open.spotify.com/artist/6d24kC5fxHFOSEAmjQPPhc',
        targetId: '6d24kC5fxHFOSEAmjQPPhc',
      },
    },
    selectedTimezone: 'Europe/Paris',
  },
  isActive: true,
};
