import React from 'react';

import { Moment } from 'moment';
import moment from 'moment-timezone';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
  AlertTitle,
  Button,
  CircularProgress,
  TextField,
  Box,
  FormHelperText,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import FieldSet from 'shared/components/FieldSet';
import {
  AUTONOTIFY_DATE_FORMAT,
  AUTONOTIFY_DATE_MASK,
  DATA_TEST_IDS,
  MAX_DAYS_AFTER_RELEASE_TO_ALLOW_EDIT,
} from 'shared/entities/autonotify/autonotify.constants';
import { AutonotifyStatus } from 'shared/entities/autonotify/autonotify.types';
import {
  getNotifyMaxDateTime,
  getNotifyMinDateTime,
} from 'shared/entities/autonotify/autonotify.utils';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useAutonotifyStatus } from 'shared/hooks/entities/autonotify';
import { backlinkBaseUrlMap } from 'shared/routes';
import { AppName } from 'shared/types';
import { getAppName } from 'shared/utils/app';

import { useValidationContext } from '../../../../hooks/useValidation';
import { useBacklinkSettingsDataEditorContext } from '../../../../state/context';
import EditorRichTextField from '../../../fields/EditorRichTextField';
import EditorTextField from '../../../fields/EditorTextField';
import EditorLocaleManager from '../../../managers/EditorLocaleManager';
import AutonotifyToggle from './AutonotifyToggle';
import SendTestMailForm from './SendTestMailForm';
import StoresManager from './StoresManager';

import * as FieldsStyled from '../../../fields/styled';
import * as EditorStyled from '../../../styled';

/**
 * Pre-release Autonotify Step - Form
 */
const AutonotifyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { autonotifyStatus, isLoading } = useAutonotifyStatus();
  const {
    stepKey,
    getFieldProps,
    editorState,
    currentLocale,
    parentEntityInfos,
  } = useBacklinkSettingsDataEditorContext();

  // Are we editing a backlink or workspace / artist link default settings ?
  const backlink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : undefined;

  // The form can only be modified if we are < than 3 days after release date
  const maxTimeToEdit = new Date(
    editorState.releaseStep.release.digitalReleaseDate,
  );
  maxTimeToEdit.setDate(
    maxTimeToEdit.getDate() + MAX_DAYS_AFTER_RELEASE_TO_ALLOW_EDIT,
  );
  const canEditFields = !backlink || maxTimeToEdit.getTime() > Date.now();

  // Date & time computation from release date
  const digitalReleaseDate = editorState.releaseStep.release.digitalReleaseDate;
  const selectedTimezone = editorState.releaseStep.release.selectedTimezone;
  const maxDateTime = getNotifyMaxDateTime(
    digitalReleaseDate,
    selectedTimezone,
  );
  const minDateTime = getNotifyMinDateTime(
    digitalReleaseDate,
    selectedTimezone,
  );
  const scheduledAtPath = `${stepKey}.scheduledAt`;
  const { value: scheduledAtRaw, changeHandler: onChangeScheduledAt } =
    getFieldProps(scheduledAtPath);
  const [scheduledAt, setScheduledAt] = React.useState<Moment | null>(
    scheduledAtRaw ? moment.tz(scheduledAtRaw, selectedTimezone) : null,
  );

  // Customize notification localized fields
  const subjectPath = `${stepKey}.locale.${currentLocale}.subject`;
  const titlePath = `${stepKey}.locale.${currentLocale}.title`;
  const ctaTextPath = `${stepKey}.locale.${currentLocale}.ctaText`;
  const bodyPath = `${stepKey}.locale.${currentLocale}.body`;
  const footerTextPath = `${stepKey}.locale.${currentLocale}.footerText`;

  // Artist URLs
  const socialSectionTitlePath = `${stepKey}.locale.${currentLocale}.socialSectionTitle`;
  const selectedStoresPath = `${stepKey}.stores`;
  const { value: selectedStores, changeHandler: handleChangeStores } =
    getFieldProps(selectedStoresPath);

  const { errors } = useValidationContext();

  const isBackstage = getAppName() === AppName.backstageMarketing;

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  if (autonotifyStatus === AutonotifyStatus.COMPLETED) {
    return (
      <>
        <EditorStyled.SuccessMessage
          sx={{ marginBottom: 1 }}
          data-testid={DATA_TEST_IDS.NOTIFICATION_MESSAGE}
        >
          {t(
            'ui.component.backlink_settings_data_editor.steps.autonotify.notifications.success',
            'Your notification has been sent!',
          )}
        </EditorStyled.SuccessMessage>
        {!!backlink && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              navigate(
                `${backlinkBaseUrlMap[getAppName()]}/${
                  backlink.id
                }/analytics/fan-notification`,
              )
            }
          >
            {t(
              'ui.component.backlink_settings_data_editor.steps.autonotify.buttons',
              'View stats',
            )}
          </Button>
        )}
      </>
    );
  }

  if (
    autonotifyStatus &&
    [AutonotifyStatus.WAITING_RETRY, AutonotifyStatus.STARTED].includes(
      autonotifyStatus,
    )
  ) {
    return (
      <EditorStyled.InfoMessage
        data-testid={DATA_TEST_IDS.NOTIFICATION_MESSAGE}
      >
        {t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.notifications.pending_retry.description',
          `Your notification is being sent. It might takes a few minutes. Don't hesitate to come back and check its status`,
        )}
      </EditorStyled.InfoMessage>
    );
  }

  if (autonotifyStatus === AutonotifyStatus.FAILED) {
    return (
      <EditorStyled.ErrorMessage
        data-testid={DATA_TEST_IDS.NOTIFICATION_MESSAGE}
      >
        <AlertTitle>
          {t(
            'ui.component.backlink_settings_data_editor.steps.autonotify.notifications.failed.title',
            `Your notification couldn't been sent`,
          )}
        </AlertTitle>
        {isBackstage ? (
          <Trans i18nKey="ui.component.backlink_settings_data_editor.steps.autonotify.notifications.failed.description.backstage">
            <p>
              We tried to send your notification twice but an error occurred
              (invalid email address, wrong format…)
            </p>
            <p>
              Please contact{' '}
              <a href="mailto:cms@believe.com">cms@believe.com</a>
            </p>
          </Trans>
        ) : (
          <Trans i18nKey="ui.component.backlink_settings_data_editor.steps.autonotify.notifications.failed.description.mkt_suite">
            <p>
              We tried to send your notification twice but an error occurred
              (invalid email address, wrong format…)
            </p>
            <p>
              Don't hesitate to contact us or you can manually export your fans
              from <Link to="/campaigns/links">My Links</Link> or from{' '}
              <Link to="/audience">Audience</Link> and import them in the
              emailing solution of your choice.
            </p>
            <p>
              Once fans are notified, don't forget to delete their information,
              unless they subscribed to the newsletter.
            </p>
          </Trans>
        )}
      </EditorStyled.ErrorMessage>
    );
  }

  return (
    <>
      <AutonotifyToggle />
      {/* Only for backlink settings */}
      {!!backlink && (
        <FieldSet
          variant="condensed"
          title={t(
            'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.date_and_time.title',
            'Send date and time',
          )}
        >
          <DateTimePicker
            ampm
            ampmInClock
            views={['year', 'month', 'day', 'hours']}
            inputFormat={AUTONOTIFY_DATE_FORMAT}
            mask={AUTONOTIFY_DATE_MASK}
            label={t(
              'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.date_and_time.fields.date_and_time.label',
              'Date & time',
            )}
            value={scheduledAt}
            onChange={(newDate: Moment | null) => {
              setScheduledAt(newDate);
              if (newDate?.isValid()) {
                newDate.set('minutes', 0);
                onChangeScheduledAt(newDate.toISOString());
              } else if (newDate === null) {
                onChangeScheduledAt(''); // To trigger a validation error after emptying the field
              }
            }}
            renderInput={(props) => (
              <TextField
                fullWidth
                id="notify-date-and-time"
                {...props}
                error={!!errors[scheduledAtPath]}
                helperText={errors[scheduledAtPath]}
              />
            )}
            disabled={!canEditFields}
            maxDateTime={maxDateTime}
            minDateTime={minDateTime}
          />
          <TextField
            disabled
            fullWidth
            id="notify-timezone"
            value={selectedTimezone}
            label={t(
              'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.date_and_time.fields.timezone.label',
              'Timezone',
            )}
            InputLabelProps={{ shrink: true }}
          />
        </FieldSet>
      )}
      <FieldSet
        variant="condensed"
        title={t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.title',
          'Customize notification',
        )}
      >
        <EditorLocaleManager />
        <Box sx={{ '&&': { marginBottom: 2 } }}>
          <EditorStyled.InfoMessage>
            {t(
              'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.description',
              'Exclamation marks in email objects are poorly scored by anti-spam as well as the words "now" or "new".',
            )}
          </EditorStyled.InfoMessage>
        </Box>
        <EditorTextField
          label={t(
            'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.subject.label',
            'Email object',
          )}
          name={subjectPath}
          {...getFieldProps(subjectPath)}
          options={{
            withReplacementTokenSelect: true,
          }}
          disabled={!canEditFields}
          errorMessage={errors[subjectPath]}
          showErrorMessage
        />
        <EditorTextField
          label={t(
            'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.title.label',
            'Header text',
          )}
          name={titlePath}
          {...getFieldProps(titlePath)}
          options={{
            withReplacementTokenSelect: true,
          }}
          disabled={!canEditFields}
          errorMessage={errors[titlePath]}
          showErrorMessage
        />
        <EditorTextField
          label={t(
            'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.ctaText.label',
            'Button label',
          )}
          name={ctaTextPath}
          {...getFieldProps(ctaTextPath)}
          disabled={!canEditFields}
          errorMessage={errors[ctaTextPath]}
          showErrorMessage
        />
        <div>
          <FieldsStyled.EditorFormLabel htmlFor={bodyPath}>
            {t(
              'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.body.label',
              'Custom text',
            )}
          </FieldsStyled.EditorFormLabel>
          <EditorRichTextField
            key={currentLocale}
            id={bodyPath}
            name={bodyPath}
            {...getFieldProps(bodyPath, { replace: true })}
            mode="basic"
            disabled={!canEditFields}
            options={{
              withReplacementTokenSelect: true,
            }}
            error={!!errors[bodyPath]}
          />
          <FormHelperText error={!!errors[bodyPath]}>
            {errors[bodyPath]}
          </FormHelperText>
        </div>
        {/* Only for workspace / artist settings */}
        {!backlink && (
          <EditorTextField
            label={t(
              'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.customize_notification.fields.footerText.label',
              'Footer text',
            )}
            name={footerTextPath}
            {...getFieldProps(footerTextPath)}
            disabled={!canEditFields}
            errorMessage={errors[footerTextPath]}
            showErrorMessage
          />
        )}
      </FieldSet>
      <FieldSet
        variant="condensed"
        title={t(
          'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.artist_urls.title',
          'Artist URLs',
        )}
      >
        <EditorTextField
          label={t(
            'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.artist_urls.fields.socialSectionTitle.label',
            'Section title',
          )}
          name={socialSectionTitlePath}
          {...getFieldProps(socialSectionTitlePath)}
          disabled={!canEditFields}
          errorMessage={errors[socialSectionTitlePath]}
          showErrorMessage
        />
        <StoresManager
          selectedStores={selectedStores}
          onChange={handleChangeStores}
        />
      </FieldSet>
      {/* Only for workspace / artist settings */}
      {!!backlink && (
        <FieldSet
          variant="condensed"
          title={t(
            'ui.component.backlink_settings_data_editor.steps.autonotify.form.fieldsets.send_test_email.title',
            'Send test email',
          )}
        >
          <SendTestMailForm />
        </FieldSet>
      )}
    </>
  );
};

export default AutonotifyForm;
