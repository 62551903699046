import React, { useRef } from 'react';

import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { Hidden, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import BacklinkSettingsDataEditor from 'shared/components/BacklinkSettingsDataEditor';
import { StepKey } from 'shared/components/BacklinkSettingsDataEditor/state';
import { EditorSaveButton } from 'shared/components/Buttons/EditorSaveButton';
import { Portal } from 'shared/components/Portal';
import * as artistApi from 'shared/entities/artist/artist.api';
import { BacklinkSettingsParentEntityType } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useAppQuery } from 'shared/hooks/useAppQuery';
import { useScrollToTop } from 'shared/hooks/useScrollToTop';
import * as EditorContainerStyled from 'shared/layouts/components/ContentPanels';

import useUpdateParentEntityBacklinkSettingsData from '../useUpdateParentEntityBacklinkSettingsData';

type Props = {
  artistId: string;
  className?: string;
};

const ArtistBacklinkSettingsEditor = ({ artistId, className }: Props) => {
  const { t } = useTranslation();

  const parentEntityType: BacklinkSettingsParentEntityType = 'Artist';
  const backlinkSettingsDataFetchPayload = {
    parentEntityType,
    parentEntityId: artistId,
  };
  const {
    data: backlinkSettingsData,
    status: fetchStatus,
    isFetching,
  } = useAppQuery('backlinkSettingsData', {
    fetcherArgs: [backlinkSettingsDataFetchPayload],
  });

  const { updateBacklinkSettings, updateStatus } =
    useUpdateParentEntityBacklinkSettingsData({
      updater: artistApi.updateBacklinkSettings,
      queryDataKey: ['backlinkSettingsData', backlinkSettingsDataFetchPayload],
    });

  // using a ref because we don't need/want to re-render when editor step changes
  const stepKeyRef = useRef(StepKey.Release);

  const headerContentRef = useRef<HTMLDivElement>(null);
  const headerContentDomElement = headerContentRef.current;

  /** scroll to top when changing step, please */
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const contentContainerDomElement = contentContainerRef.current;
  useScrollToTop(contentContainerDomElement, stepKeyRef.current);

  return (
    <EditorContainerStyled.Container className={className}>
      <EditorContainerStyled.Header
        title={
          <div>
            <Typography variant="h6" noWrap>
              {t(
                'ui.component.backlink_settings_editor.title',
                'Edit link settings',
              )}
              {fetchStatus === 'loading' && (
                <CircularProgress
                  size={15}
                  css={(theme) => css`
                    margin-left: ${theme.spacing(1)};
                  `}
                />
              )}
            </Typography>
          </div>
        }
        content={<div ref={headerContentRef}></div>}
      />
      <EditorContainerStyled.Content ref={contentContainerRef}>
        {backlinkSettingsData ? (
          <>
            <EditorContainerStyled.ContentLeft lg={7}>
              <BacklinkSettingsDataEditor
                parentEntityInfos={{ type: 'Artist' }}
                initialState={backlinkSettingsData}
                onStepChange={(stepKey) => {
                  stepKeyRef.current = stepKey;
                }}
                renderActions={({ editorState, hasValidationErrors }) => {
                  return (
                    headerContentDomElement && (
                      <Portal domContainer={headerContentDomElement}>
                        <EditorSaveButton
                          showFetching={isFetching}
                          showSaving={updateStatus === 'loading'}
                          showHasErrors={hasValidationErrors}
                          onClick={() => {
                            updateBacklinkSettings({
                              artistId,
                              backlinkSettings: editorState,
                            });
                          }}
                        />
                      </Portal>
                    )
                  );
                }}
              />
            </EditorContainerStyled.ContentLeft>
            <Hidden lgDown>
              <EditorContainerStyled.ContentRight item lg={5}>
                {/* SOME CONTENT */}
              </EditorContainerStyled.ContentRight>
            </Hidden>
          </>
        ) : null}
      </EditorContainerStyled.Content>
    </EditorContainerStyled.Container>
  );
};

export default ArtistBacklinkSettingsEditor;
