import { api } from '../api';
import * as artistApi from '../artist/artist.api';
import * as backlinkApi from '../backlink/backlink.api';
import * as workspaceApi from '../workspace/workspace.api';
import {
  CreateDefaultBacklinkSettingsPayload,
  UpdateDefaultBacklinkSettingsPayload,
  FetchDefaultBacklinkSettingsPayload,
} from './backlinkSettings.api.types';
import { BACKLINK_SETTINGS_FALLBACK_LOCALE } from './backlinkSettings.constants';
import {
  BacklinkSettings,
  BacklinkSettingsData,
  BacklinkSettingsParentEntityType,
  BacklinkSettingsStepsTemplate,
  BacklinkSettingsType,
  EditableDefaultBacklinkSettingsType,
  EditableDefaultBacklinkSettings,
} from './backlinkSettings.types';
import { BacklinkSettingsLocaleContentTemplate } from './v2/types';

/**
 * allows accessing {@link BacklinkSettingsData} fetchers for a parent entity
 */
export const backlinkSettingsDataForParentEntityFetchers: Record<
  BacklinkSettingsParentEntityType,
  (parentEntityId?: string) => Promise<BacklinkSettingsData>
> = {
  Artist: async (artistId?: string) => {
    if (artistId) {
      const backlinkSettings = await artistApi.fetchBacklinkSettings(artistId);
      return backlinkSettings.data;
    }
    return Promise.reject();
  },
  Backlink: async (backlinkId?: string) => {
    if (backlinkId) {
      const backlink = await backlinkApi.fetchBacklink(backlinkId);
      return backlink.settingsData;
    }
    return Promise.reject();
  },
  Workspace: async (workspaceId?: string) => {
    const backlinkSettings = await workspaceApi.fetchBacklinkSettings(
      workspaceId,
    );
    return backlinkSettings.data;
  },
};

export const fetchAllDefaultBacklinkSettings = async ({
  type,
}: {
  type?: EditableDefaultBacklinkSettingsType;
}) => {
  const params: FetchDefaultBacklinkSettingsPayload = {};
  if (type === BacklinkSettingsType.DEFAULT_EMPLOYEE) {
    params.onlyEmployee = true;
  } else if (type === BacklinkSettingsType.DEFAULT_CUSTOMER) {
    params.onlyCustomer = true;
  }
  const results = await api.get<EditableDefaultBacklinkSettings[]>(
    `backlinkSettings`,
    {
      params,
    },
  );
  return results.data;
};

export const createDefaultBacklinkSettings = async (
  payload: CreateDefaultBacklinkSettingsPayload,
) => {
  const response = await api.post<BacklinkSettings>(
    'backlinkSettings',
    payload,
  );
  return response.data;
};

export const updateDefaultBacklinkSettings = async ({
  backlinkSettingsId,
  settingsData,
}: UpdateDefaultBacklinkSettingsPayload) => {
  const response = await api.patch<BacklinkSettings>(
    `backlinkSettings/${backlinkSettingsId}`,
    { settingsData },
  );
  return response.data;
};

/**
 * is only used in for default backlinkSettings
 * as the backedn only allows deleting those
 *
 * @param backlinkSettingsId
 * @returns number
 */
export const deleteDefaultBacklinkSettings = async (
  backlinkSettingsId: string,
) => {
  const results = await api.delete<number>(
    `backlinkSettings/${backlinkSettingsId}`,
  );
  return results;
};

export const fetchBacklinkSettingsStepsTemplate = async (
  locale: string,
  fallbackLocale: string,
): Promise<BacklinkSettingsStepsTemplate> => {
  const fileName = `backlinkSettingsSteps.template.${locale}.json`;
  try {
    const results = await import(`./templates/steps/${fileName}`);
    const template: BacklinkSettingsStepsTemplate = await new Promise(
      (resolve) =>
        setTimeout(() => {
          resolve(results.default);
        }, 500),
    );
    return template;
  } catch (error: any) {
    console.warn(
      `Could not find a backlinkSettings template for '${locale}' locale, falling back to: '${fallbackLocale}'`,
    );
    return fetchBacklinkSettingsStepsTemplate(
      fallbackLocale,
      BACKLINK_SETTINGS_FALLBACK_LOCALE,
    );
  }
};

export const fetchBacklinkSettingsV2LocaleTemplate = async (
  locale: string,
  fallbackLocale: string,
): Promise<BacklinkSettingsLocaleContentTemplate> => {
  const fileName = `backlinkSettingsV2Locale.template.${locale}.json`;
  try {
    const results = await import(`./v2/templates/${fileName}`);
    const template: BacklinkSettingsLocaleContentTemplate = await new Promise(
      (resolve) =>
        setTimeout(() => {
          resolve(results.default);
        }, 500),
    );
    return template;
  } catch (error: any) {
    console.warn(
      `Could not find a backlinkSettings template for '${locale}' locale, falling back to: '${fallbackLocale}'`,
    );
    return fetchBacklinkSettingsV2LocaleTemplate(
      fallbackLocale,
      BACKLINK_SETTINGS_FALLBACK_LOCALE,
    );
  }
};

export async function enableAutoscan(storeName: string) {
  return api.post(
    `backlinkSettings/customs/stores/${storeName}/autoscan/enable`,
  );
}
