export enum StoreType {
  Stream = 'stream',
  Buy = 'buy',
  Download = 'download',
  Watch = 'watch',
  Follow = 'follow',
  Other = 'other',
}

export const isStoreType = (value: any): value is StoreType => {
  return (
    typeof value === 'string' &&
    Object.values(StoreType).includes(value as StoreType)
  );
};

export type StoreConfig = {
  name: string;
  displayName: string;
  type: StoreType;
  canUseAutoscan?: boolean;
  hasConnect?: boolean;
  releaseFallbackSearchUrlTemplate?: string;
  releaseFallbackSearchMobileCompatible?: boolean;
  releaseFallbackSearchDesktopCompatible?: boolean;
};

export type StoresConfigs = Record<string, StoreConfig>;
