import {
  PrereleaseCTAMode,
  FormFieldName,
  BacklinkSettingsData,
  FormFieldType,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { RELEASE_LINK_DEFAULT_KEY } from 'shared/entities/release/release.constants';

export const common = {
  defaultLocale: 'en',
  defaultActionId: '',
};

export const releaseStep = {
  release: {
    selectedTimezone: 'Europe/Paris',
    digitalReleaseDate: '2050-03-27T20:00:00.000Z',
    stores: {
      spotify: {
        name: 'spotify',
        useAutoscan: true,
        links: { [RELEASE_LINK_DEFAULT_KEY]: { url: '' } },
      },
      deezer: {
        name: 'deezer',
        useAutoscan: true,
        links: { [RELEASE_LINK_DEFAULT_KEY]: { url: '' } },
      },
      appleMusic: {
        name: 'appleMusic',
        useAutoscan: true,
        links: { [RELEASE_LINK_DEFAULT_KEY]: { url: '' } },
      },
      itunes: {
        name: 'itunes',
        useAutoscan: false,
        links: { [RELEASE_LINK_DEFAULT_KEY]: { url: '' } },
      },
      amazonMusic: {
        name: 'amazonMusic',
        useAutoscan: false,
        links: { [RELEASE_LINK_DEFAULT_KEY]: { url: '' } },
      },
    },
    title: 'Release title',
  },
};

export const designStep: BacklinkSettingsData['designStep'] = {
  // this image ID matches an image that has been uploaded to the image service (SBX aws env)
  // this is to show an image in the preview in mock mode
  // it is done this way as we could not make the MSW handlers work with binary responses in the bfan link code
  artworkId: 'e13a3dc5-3280-41be-aa72-9a2cf9de4d87',

  theme: 'backlinkTheme001',
  typography: {
    link: { color: '#ffffff' },
    paragraph: { color: '#ffffff' },
  },
  background: {
    direction: 135,
    color01: '#717A97',
    image: {
      imageId: '2a86acb8-d2b2-47a1-8faf-bea07260541a',
      overlay: { color: '#717A97', opacity: 0.7 },
      blur: 0.3,
    },
  },
};

export const prereleaseLandingStep: BacklinkSettingsData['prereleaseLandingStep'] =
  {
    showArtwork: true,
    showArtist: false,
    showVideo: false,
    youtubeVideoId: '',
    locale: {
      fr: {
        richText:
          '<h2>Nouveau single "{releaseTitle}"</h2>\n<p>Ne manque pas la sortie le {digitalReleaseDate} ! Choisis ta plateforme préférée :</p>',
        callToActions: {
          stores: {
            displayOrder: [
              'spotify',
              'deezer',
              'appleMusic',
              'itunes',
              'amazonMusic',
            ],
            options: {
              spotify: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Enregistrer',
                text: '',
              },
              deezer: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Enregistrer',
                text: '',
              },
              appleMusic: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Enregistrer',
                text: '',
              },
              itunes: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Télécharger',
                text: '',
              },
              amazonMusic: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Enregistrer',
                text: '',
              },
            },
          },
        },
      },
      en: {
        richText:
          '<h2>New single "{releaseTitle}"</h2>Out on {digitalReleaseDate}! Select your favorite platform:',
        callToActions: {
          stores: {
            displayOrder: [
              'spotify',
              'deezer',
              'appleMusic',
              'itunes',
              'amazonMusic',
            ],
            options: {
              spotify: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Pre-save',
                text: '',
              },
              deezer: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Pre-save',
                text: '',
              },
              appleMusic: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Pre-save',
                text: '',
              },
              itunes: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Pre-save',
                text: '',
              },
              amazonMusic: {
                isDisplayed: true,
                mode: PrereleaseCTAMode.DEFAULT,
                label: 'Pre-save',
                text: '',
              },
            },
          },
        },
      },
    },
  };

export const prereleasePresaveStep: BacklinkSettingsData['prereleasePresaveStep'] =
  {
    locale: {
      fr: {
        notificationText:
          '{releaseTitle} sera ajouté à ta librairie le {digitalReleaseDate} !',
      },
      en: {
        notificationText:
          '{releaseTitle} will be added to your library on {digitalReleaseDate}!',
      },
    },
    actionIds: [],
  };

export const prereleaseFormStep: BacklinkSettingsData['prereleaseFormStep'] = {
  isActive: true,
  locale: {
    fr: {
      richText:
        '<h1 style="text-align: center;">Presque fini 🔥</h1><p style="text-align: center;">Complète tes infos pour être notifié·e à la sortie :</p>',
      form: {
        fields: [
          {
            required: true,
            name: FormFieldName.Email,
            type: FormFieldType.Email,
            label: 'Email',
          },
        ],
        optins: [
          {
            required: false,
            name: FormFieldName.OptinSubscription,
            label:
              "J'accepte de recevoir news et offres de {artistName} par Believe. J'ai plus de 15 ans.",
            type: FormFieldType.Checkbox,
            metas: { consentToOffers: true, consentToNews: true },
          },
        ],
        submitButton: { label: 'Valider' },
        skipButton: { label: 'Passer' },
      },
    },
    en: {
      richText:
        '<h1 style="text-align: center;">🔥 Almost done!</h1><p style="text-align: center;">Submit your info to get notified on release:</p>',
      form: {
        fields: [
          {
            required: true,
            name: FormFieldName.Email,
            type: FormFieldType.Email,
            label: 'Email',
          },
        ],
        optins: [
          {
            required: false,
            name: FormFieldName.OptinSubscription,
            label:
              'Send me future news and offers of {artistName} from Believe. I am over 15 y.o.',
            type: FormFieldType.Checkbox,
            metas: { consentToOffers: true, consentToNews: true },
          },
        ],
        submitButton: { label: 'Submit' },
        skipButton: { label: 'Skip' },
      },
    },
  },
  redirect: {
    useStoresRedirects: true,
    default: { url: '' },
    stores: [
      { url: '', id: 'spotify' },
      { url: '', id: 'deezer' },
      { url: '', id: 'appleMusic' },
      { url: '', id: 'itunes' },
      { url: '', id: 'amazonMusic' },
    ],
  },
};

export const prereleaseAutonotifyStep: BacklinkSettingsData['prereleaseAutonotifyStep'] =
  {
    isActive: true,
    stores: ['spotify', 'appleMusic'],
    scheduledAt: '2050-03-28T10:00:00.000Z',
    selectedTimezone: 'Europe/Paris',
    locale: {
      en: {
        subject: '{releaseTitle} is out!',
        title: '{releaseTitle} is now available!',
        body: `{releaseTitle} is out now everywhere! Can't wait for you to hear it.`,
        ctaText: 'Listen Now',
        socialSectionTitle: 'Follow me',
        footerText:
          "You won't receive other email from us, except if you subscribed to the newsletter.",
      },
      fr: {
        subject: '{releaseTitle} est sorti !',
        title: '{releaseTitle} est maintenant disponible !',
        body: `{releaseTitle} est sorti maintenant sur toutes les plateformes! N'attendez plus pour l'écouter!.`,
        ctaText: 'Écouter',
        socialSectionTitle: 'Suivez-moi',
        footerText:
          "Vous ne recevrez pas d'autres emails de notre part, sauf si vous vous êtes abonné·e à la newsletter.",
      },
    },
  };

export const postreleaseLandingStep: BacklinkSettingsData['postreleaseLandingStep'] =
  {
    showArtwork: true,
    showArtist: true,
    showVideo: false,
    youtubeVideoId: '',
    locale: {
      fr: {
        richText:
          '<h2 style="text-align: center;">{artistName} - {releaseTitle}</h2><p style="text-align: center;">Choisis ta plateforme préférée :</p>',
        callToActions: {
          stores: {
            displayOrder: [
              'spotify',
              'deezer',
              'appleMusic',
              'itunes',
              'amazonMusic',
            ],
            options: {
              spotify: { isDisplayed: true, label: 'Écouter', text: '' },
              deezer: { isDisplayed: true, label: 'Écouter', text: '' },
              appleMusic: { isDisplayed: true, label: 'Écouter', text: '' },
              itunes: { isDisplayed: true, label: 'Télécharger', text: '' },
              amazonMusic: { isDisplayed: true, label: 'Écouter', text: '' },
            },
          },
        },
      },
      en: {
        richText:
          '<h2 style="text-align: center;">{artistName} - {releaseTitle}</h2><p style="text-align: center;">Select your favorite platform:</p>',
        callToActions: {
          stores: {
            displayOrder: [
              'spotify',
              'deezer',
              'appleMusic',
              'itunes',
              'amazonMusic',
            ],
            options: {
              spotify: { isDisplayed: true, label: 'Listen', text: '' },
              deezer: { isDisplayed: true, label: 'Listen', text: '' },
              appleMusic: { isDisplayed: true, label: 'Listen', text: '' },
              itunes: { isDisplayed: true, label: 'Download', text: '' },
              amazonMusic: { isDisplayed: true, label: 'Listen', text: '' },
            },
          },
        },
      },
    },
  };

export const sharingStep = {
  url: { subDomain: '', slug: '' },
  socialCard: {
    imageId: '',
    title: '{artistName} - {releaseTitle}',
    description: 'Sortie le {digitalReleaseDateFull}',
  },
};
