export interface PlatformConfig {
  targetUrl: string;
  targetId: string;
}

export interface PlatformsList {
  spotify?: PlatformConfig;
  deezer?: PlatformConfig;
  appleMusic?: PlatformConfig;
}

export interface ActionConfig {
  platforms: PlatformsList;
  selectedTimezone?: string;
}

export enum ActionTypeEnum {
  FOLLOW_ARTIST = 'FOLLOW_ARTIST',
  FOLLOW_USER = 'FOLLOW_USER',
  ADD_ALBUM = 'ADD_ALBUM',
  ADD_TRACK = 'ADD_TRACK',
  ADD_PLAYLIST = 'ADD_PLAYLIST',
}

export enum ActionScheduleTypeEnum {
  SCHEDULED_NOW = 'SCHEDULED_NOW',
  SCHEDULED_WITH_DATE = 'SCHEDULED_WITH_DATE',
  SCHEDULED_ON_BACKLINK_RELEASE_DATE = 'SCHEDULED_ON_BACKLINK_RELEASE_DATE',
}

export enum ActionTriggerStatus {
  QUEUED = 'QUEUED',
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export type Action = {
  id: string;
  type: ActionTypeEnum;
  scheduleType: ActionScheduleTypeEnum;
  config: ActionConfig;
  targetName: string;
  artistId?: string;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  schedulableOn?: string;
  backlink?: any;
  subsKPI?: any;
};

export interface ActionSub {
  [key: string]: any; // any other prop
}
