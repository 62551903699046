import { rest } from 'msw';

import config from 'shared/config';
import { ActionApiPayload } from 'shared/entities/action/action.api.types';
import { Action } from 'shared/entities/action/action.types';
import { action } from 'shared/entities/action/mocks/fixtures/action.fixture.one';
import { transformBacklinkSettingsDataV1ToV2 } from 'shared/entities/backlinkSettings/mocks/fixtures/backlinkSettings.fixture.helpers';
import { getRelease } from 'shared/entities/release/mocks/fixtures/release.fixture.all';

import { UpdateBacklinkPayload } from '../../backlink.api.types';
import { paginatedBacklinks as paginatedAdminBacklinks } from '../fixtures/backlink.admin.fixture.all.paginated';
import {
  generateBacklinkAnalyticsOverviewFixture,
  generateBacklinkAnalyticssourcesByReferrerFixture,
  generateBacklinkAnalyticssourcesByChannelFixture,
  generateBacklinkAnalyticsLocationsByCountryFixture,
  generateBacklinkAnalyticsLocationsByCityFixture,
  generateBacklinkAnalyticsDevicesFixture,
  generateBacklinkAnalyticsStoreClicksByStoreFixture,
  generateBacklinkAnalyticsStoreClicksByStoreTypeFixture,
  generateBacklinkAnalyticsPresavesFixture,
  generateBacklinkAnalyticsOptinsFixture,
  generateBacklinkAnalyticsFanNotificationFixture,
} from '../fixtures/backlink.analytics.fixture';
import { getBacklink } from '../fixtures/backlink.fixture.all';
import {
  getPaginatedBacklinks,
  paginatedBacklinks,
} from '../fixtures/backlink.fixture.all.paginated';
import { backlink, refreshedBacklink } from '../fixtures/backlink.fixture.one';
import { backlinksPerformance } from '../fixtures/backlink.fixture.performance';
import { shareExtensionsFixture } from '../fixtures/shareExtensions.fixture';

const backlinkBaseApiPath = '/backlinks';

export const DEFAULT_BACKLINKS_COUNT_FOR_A_RELEASE = 1;

/**
 * Refreshing backlink also refreshes the release in the backend
 * To reproduce this behavior, we export a variable
 * to know if the backlink has been refreshed
 */
export let backlinkRefreshed = false;
export const makeHandlers = (apiBaseUrl: string) => [
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/slugIsAvailable`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          query: {
            slug: req.url.searchParams.get('slug'),
            backlinkId: req.url.searchParams.get('backlinkId'),
          },
          isValid: true,
          isAvailable: true,
        }),
      );
    },
  ),
  /** PERFORMANCES */
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/performance`,
    async (req, res, ctx) => {
      const mockedResponse = backlinksPerformance;
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  // GET - all
  rest.get(`${apiBaseUrl}${backlinkBaseApiPath}`, async (req, res, ctx) => {
    const page = Number(req.url.searchParams.get('page')) || 0;
    const limit = Number(req.url.searchParams.get('limit')) || 10;
    const query = req.url.searchParams.get('query') || '';
    const artistIds = req.url.searchParams.getAll('artistIds[]');
    const sortBy = req.url.searchParams.get('sortBy') || '';
    const sortDirection = req.url.searchParams.get('sortDirection') || '';
    const mockedResponse = getPaginatedBacklinks({
      page,
      limit,
      params: { artistIds, query },
      sortBy,
      sortDirection,
    });
    if (req.url.searchParams.get('releaseId')) {
      const releaseBacklink = getBacklink(
        '6caeae3d-d2de-4e59-971d-e03a4f3ca751',
      );
      const releaseBacklinks = releaseBacklink ? [releaseBacklink] : [];
      mockedResponse.backlinks = releaseBacklinks;
    }
    return res(ctx.delay(1000), ctx.status(200), ctx.json(mockedResponse));
  }),
  // GET - admin
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/admin`,
    async (req, res, ctx) => {
      const mockedResponse = paginatedAdminBacklinks;
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  // POST - create backlink
  rest.post(`${apiBaseUrl}${backlinkBaseApiPath}`, (req, res, ctx) => {
    const mockedResponse = backlink;
    return res(ctx.status(200), ctx.json(mockedResponse));
  }),
  // GET - read backlink
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId`,
    (req, res, ctx) => {
      let mockedResponse = backlink;
      if (backlinkRefreshed) {
        mockedResponse = refreshedBacklink;
      }
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  // GET - read backlink settings v2
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/v2/:backlinkId`,
    (req, res, ctx) => {
      let mockedResponse = {
        ...backlink,
        settingsData: transformBacklinkSettingsDataV1ToV2(
          backlink.settingsData,
        ),
      };
      if (backlinkRefreshed) {
        mockedResponse = {
          ...backlink,
          settingsData: transformBacklinkSettingsDataV1ToV2(
            refreshedBacklink.settingsData,
          ),
        };
      }
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  // PUT - refresh backlink
  rest.put(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/release/metadata/refresh`,
    (req, res, ctx) => {
      backlinkRefreshed = true;
      const mockedResponse = {
        ...refreshedBacklink,
        settingsDataV2: transformBacklinkSettingsDataV1ToV2(
          refreshedBacklink.settingsData,
        ),
      };
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  // PATCH - update backlink
  rest.patch<UpdateBacklinkPayload>(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId`,
    async (req, res, ctx) => {
      const payload = await req.json();
      const settingsData = payload?.settingsData;
      const mockedResponse = {
        ...backlink,
        settingsData,
        settingsDataV2: transformBacklinkSettingsDataV1ToV2(settingsData),
      };
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  // DELETE - delete backlink
  rest.delete<never, { backlinkId: string }>(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId`,
    (req, res, ctx) => {
      const backlinkId = req.params.backlinkId;
      const backlinkToDeleteIndex = paginatedBacklinks.backlinks.findIndex(
        (backlink) => backlink.id === backlinkId,
      );
      if (backlinkToDeleteIndex !== -1) {
        paginatedBacklinks.backlinks.splice(backlinkToDeleteIndex, 1);
      }
      return res(ctx.status(200));
    },
  ),

  /** Actions */

  rest.post<ActionApiPayload, { backlinkId: string }, Action>(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/actions`,
    async (req, res, ctx) => {
      const actionToBeCreated = await req.json();
      const backlink = getBacklink(req.params.backlinkId);
      const release = backlink && getRelease(backlink.releaseId);
      const mockedResponse: Action = {
        ...action,
        ...actionToBeCreated,
        id: action.id,
        artistId: release?.artist?.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),

  /** ANALYTICS */

  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/overviewInsight`,
    (req, res, ctx) => {
      const mockedResponse = generateBacklinkAnalyticsOverviewFixture({
        count: 120,
      });
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/referrersSources`,
    (req, res, ctx) => {
      const mockedResponse =
        generateBacklinkAnalyticssourcesByReferrerFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/channelsSources`,
    (req, res, ctx) => {
      const mockedResponse = generateBacklinkAnalyticssourcesByChannelFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/countriesVisits`,
    (req, res, ctx) => {
      const mockedResponse =
        generateBacklinkAnalyticsLocationsByCountryFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/citiesVisits`,
    (req, res, ctx) => {
      const mockedResponse = generateBacklinkAnalyticsLocationsByCityFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/devicesVisits`,
    (req, res, ctx) => {
      const mockedResponse = generateBacklinkAnalyticsDevicesFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/storesConversionClicks`,
    (req, res, ctx) => {
      const mockedResponse =
        generateBacklinkAnalyticsStoreClicksByStoreFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/storesConversionTypes`,
    (req, res, ctx) => {
      const mockedResponse =
        generateBacklinkAnalyticsStoreClicksByStoreTypeFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/actionsConversionPresave`,
    (req, res, ctx) => {
      const mockedResponse = generateBacklinkAnalyticsPresavesFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/autonotify`,
    (req, res, ctx) => {
      const mockedResponse = generateBacklinkAnalyticsFanNotificationFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
  rest.get(
    `${apiBaseUrl}${backlinkBaseApiPath}/:backlinkId/analytics/subscribersConversion`,
    (req, res, ctx) => {
      const mockedResponse = generateBacklinkAnalyticsOptinsFixture();
      return res(ctx.status(200), ctx.json(mockedResponse));
    },
  ),
];

const onlyBackstageHandlers = [
  rest.post(
    `${config.backstageMarketingApiBaseUrl}${backlinkBaseApiPath}/fromInternalProductId`,
    (req, res, ctx) =>
      res(ctx.delay(1000), ctx.status(200), ctx.json(backlink)),
  ),
];

// we need those handlers as a separate array to be able to use them in the initialization of the mocks
// they are requested at the App initialization
export const shareExtensionsHandlers = [
  rest.get(
    `${config.marketingSuiteApiBaseUrl}${backlinkBaseApiPath}/shareExtensions`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(shareExtensionsFixture));
    },
  ),
  rest.get(
    `${config.backstageMarketingApiBaseUrl}${backlinkBaseApiPath}/shareExtensions`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(shareExtensionsFixture));
    },
  ),
];

const handlers = [
  ...makeHandlers(config.marketingSuiteApiBaseUrl),
  ...makeHandlers(config.backstageMarketingApiBaseUrl),
  ...onlyBackstageHandlers,
];

export default handlers;
