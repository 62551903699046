import * as React from 'react';

import { Space } from 'App.styled';
import without from 'lodash/without';
import { useTranslation } from 'react-i18next';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Divider } from '@mui/material';

import ActionEditor from 'shared/components/ActionEditor';
import { usePreview } from 'shared/components/BacklinkSettingsDataEditor/hooks/usePreview';
import FieldSet from 'shared/components/FieldSet';
import { parentEntityIsBacklink } from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { useAppContext, getWorkspaceDefaultTimezone } from 'shared/state';

import { useBacklinkSettingsDataEditorContext } from '../../state/context';
import ReleaseStaticCard from '../cards/ReleaseStaticCard';
import EditorTextField from '../fields/EditorTextField';
import EditorLocaleManager from '../managers/EditorLocaleManager';
import type { PrereleasePresaveStepProps } from './types';

import { EditorButton } from '../styled';
import { StepContent } from './styled';

export const PrereleasePresaveStep: React.FC<PrereleasePresaveStepProps> = () => {
  const { t } = useTranslation();

  const { state: appState } = useAppContext();
  const {
    stepKey,
    parentEntityInfos,
    editorState,
    getFieldProps,
    currentLocale,
  } = useBacklinkSettingsDataEditorContext();

  /** for Backlink */
  const backlink = parentEntityIsBacklink(parentEntityInfos)
    ? parentEntityInfos.entity
    : undefined;
  const backlinkId = backlink?.id;
  const releaseId = backlink?.releaseId;

  const notificationTextPath = `${stepKey}.locale.${currentLocale}.notificationText`;

  /**
   * Secondary actions management
   */
  const actionIdsPath = `${stepKey}.actionIds`;
  const {
    value: actionIds = [],
    changeHandler: actionIdsChangeHandler,
  } = getFieldProps(actionIdsPath);
  const [isCreatingAction, setIsCreatingAction] = React.useState(false);

  /**
   * Preview mode helpers
   */
  const {
    getDSPActionsInfosAddHandler,
    getDSPActionsInfosUpdateHandler,
    getDSPActionsInfosRemoveHandler,
  } = usePreview({ getFieldProps });

  return (
    <StepContent>
      <FieldSet
        title={t(
          'ui.component.backlink_settings_data_editor.steps.prereleasePresaveStep.form.fieldset.main_dsp_action.title',
          'Main Pre-save',
        )}
        variant="condensed"
      >
        {releaseId && (
          <Space bottom={3}>
            <ReleaseStaticCard releaseId={releaseId} />
          </Space>
        )}
        <EditorLocaleManager />
        <Space bottom={3}>
          <EditorTextField
            label={t(
              'ui.component.backlink_settings_data_editor.steps.prereleasePresaveStep.form.field.success_notification.label',
              'Success notification',
            )}
            name={notificationTextPath}
            multiline
            {...getFieldProps(notificationTextPath)}
            options={{
              withReplacementTokenSelect: true,
            }}
          />
        </Space>
      </FieldSet>
      {backlinkId && (
        <FieldSet
          title={t(
            'ui.component.backlink_settings_data_editor.steps.prereleasePresaveStep.form.fieldset.secondary_dsp_actions.title',
            'Secondary actions',
          )}
          variant="condensed"
        >
          {actionIds.map((actionId) => (
            <ActionEditor
              key={actionId}
              actionId={actionId}
              backlinkId={backlinkId}
              onActionUpdated={(action) => {
                /* for Preview mode */
                getDSPActionsInfosUpdateHandler(action, editorState);
              }}
              onActionRemoved={() => {
                actionIdsChangeHandler(without(actionIds, actionId));

                /* for Preview mode */
                getDSPActionsInfosRemoveHandler()(actionId);
              }}
              defaultTimezone={getWorkspaceDefaultTimezone(appState)}
            />
          ))}
          {isCreatingAction && (
            <ActionEditor
              backlinkId={backlinkId}
              onActionCreated={(action) => {
                const { id } = action;
                setIsCreatingAction(false);
                actionIdsChangeHandler(actionIds.concat(id));

                /** for Preview mode */
                getDSPActionsInfosAddHandler(action, editorState)();
              }}
              onCancel={() => setIsCreatingAction(false)}
              defaultTimezone={getWorkspaceDefaultTimezone(appState)}
            />
          )}
          {(actionIds.length > 0 || isCreatingAction) && <Divider />}
          <EditorButton
            startIcon={<AddCircleRoundedIcon />}
            disabled={false}
            onClick={() => setIsCreatingAction(true)}
          >
            {t(
              'ui.component.backlink_settings_data_editor.action_editor.button.add_action',
              'Add action',
            )}
          </EditorButton>
        </FieldSet>
      )}
    </StepContent>
  );
};
