import {
  ActionScheduleTypeEnum,
  ActionTypeEnum,
  PlatformConfig,
  PlatformsList,
} from 'shared/entities/action/action.types';
import { ActionMap } from 'shared/state/types';

export enum ActionsTypes {
  SetType = 'SET_ACTION_TYPE',
  SetTargetName = 'SET_TARGET_NAME',
  SetScheduleType = 'SET_SCHEDULE_TYPE',
  SetSchedulableOn = 'SET_SCHEDULABLE_ON',
  SetSelectedTimezone = 'SET_SELECTED_TIMEZONE',
  SetPlatformConfig = 'SET_PLATFORM_CONFIRM',
  RemovePlatform = 'REMOVE_PLATFORM',
  SetUrlIsBeingChecked = 'SET_URL_IS_BEING_CHECKED',
  SetErrors = 'SET_ERRORS',
}

type PlatformName = keyof PlatformsList;

type SetPlatformConfigPayload = {
  platform: PlatformName;
  config: PlatformConfig;
};

type SetUrlIsBeingCheckedPayload = {
  platform: PlatformName;
  value: boolean;
};

export type Payload = {
  [ActionsTypes.SetType]: ActionTypeEnum;
  [ActionsTypes.SetTargetName]: string;
  [ActionsTypes.SetScheduleType]: ActionScheduleTypeEnum;
  [ActionsTypes.SetSchedulableOn]: string;
  [ActionsTypes.SetSelectedTimezone]: string;
  [ActionsTypes.SetPlatformConfig]: SetPlatformConfigPayload;
  [ActionsTypes.RemovePlatform]: PlatformName;
  [ActionsTypes.SetUrlIsBeingChecked]: SetUrlIsBeingCheckedPayload;
  [ActionsTypes.SetErrors]: string[];
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];
