import { UserEmployee } from '../user/user.types';
import {
  AdCampaignTargetingType,
  AdCampaignTargetingGender,
  AdCampaignPlatform,
  AdCampaignStatus,
  AdCampaignPlacement,
  AvailablePlatforms,
  CampaignStatus,
  CampaignSetupMethod,
  PlatformStatus,
  Currency,
  Objective,
  AvailableReleaseTypes,
  AvailableReleaseDates,
  SanityCheckPlanStatus,
  AvailableAdsUsersProfileTypes,
  BusinessUnitTypes,
} from './ads.constants';

export type FilledAdCampaign = {
  key: string;
  name: string;
  platform: AdCampaignPlatform;
  status: AdCampaignStatus;
  budget: number;
  spent: number;
  period: AdCampaignPeriod;
  placement: AdCampaignPlacement[];
  targeting: AdCampaignTargeting[];
  creatives: Array<AdCampaignCreativeYT | AdCampaignCreativeFBInsta>;
};

type MandatoryProps = 'key' | 'name' | 'platform' | 'status' | 'placement';
type OptionalProps = Omit<FilledAdCampaign, MandatoryProps>;
export type DraftAdCampaign = Pick<FilledAdCampaign, MandatoryProps> & {
  [Property in keyof OptionalProps]?: OptionalProps[Property];
};

export type AdCampaign = FilledAdCampaign | DraftAdCampaign;

export function isDraftAdCampaign(
  adCampaign: AdCampaign,
): adCampaign is DraftAdCampaign {
  return adCampaign.status === AdCampaignStatus.Draft;
}

export type AdCampaignPeriod = {
  start: string;
  end: string;
};

export type AdCampaignTargeting = {
  key: string;
  type: AdCampaignTargetingType;
  interests: Interest[];
  locations: Location[];
  gender: AdCampaignTargetingGender;
  age?: ReferentialAgeRanges['facebook']; // required for FB/IG/FB_MIX platform
  ageRange?: ReferentialAgeRanges['youtube']; // required for YT platform
};

export type Interest = {
  key: string;
  label: string;
};

export type Location = {
  key: string;
  name: string;
  type: string;
};

export type FbCTA = {
  label: string;
  action: string;
};

export type ReferentialAgeRanges = {
  youtube: Array<{
    min: number;
    max?: number;
    key: string;
  }>;
  facebook: {
    min: number;
    max: number;
  };
};

export type AdCampaignCreativeYT = {
  key: string;
  youtubeUrl: string;
  finalUrl: string;
  displayUrl: string;
  CTA: string;
  headline: string;
};
export function isCreativeForYoutube(dto: any): dto is AdCampaignCreativeYT {
  return !!dto.youtubeUrl;
}

export type AdCampaignCreativeFBInsta = {
  key: string;
  primaryText: string;
  linkToPromote: string;
  linkToDisplay: string;
  headline: string;
  subtitle: string;
  CTA: string;
  videoSources: {
    feed: string;
    story: string;
  };
};
export function isCreativeForFacebookAndInstagram(
  dto: any,
): dto is AdCampaignCreativeFBInsta {
  return !!dto.videoSources && !!dto.linkToPromote && !!dto.CTA;
}

export type TermObject = {
  id: string;
  label: string;
  [key: string]: any;
};

export type AvailableCredits = {
  spent: number;
  engaged: number;
  available: number;
  total: number;
  currency: string;
};

export type AdCampaignStats = {
  budget: number;
  opt: number;
  spent: number;
  dayStats: Array<{
    day: string;
    spent: number;
    impressions: number;
    videoViews: number;
    clicks: number;
    thruplay: number;
    cpc: number;
    ctr: number;
    cpv: number;
  }>;
  impressions: number;
  videoViews: number;
  clicks: number;
  thruplay: number;
  cpc: number;
  ctr: number;
  cpv: number;
};

export type AdsSettingsCustomer = {
  userCustomerExternalId: string;
  allTimeQuantity: number;
  currency: string;
  projectId: string;
  businessUnits: string;
};

export type GetAllAdsSettingsCustomerPayload = {
  page: number;
  limit: number;
  userCustomerExternalId?: string;
};

export type AdsPlan = {
  id?: number;
  name: string;
  businessUnitId: number;
  metadata: {
    [key: string]: string;
  };
  creationDate: string;
};

export type Ads = {
  artworkUrl: string;
  backstageAdsPlan: AdsPlan;
  backstageAdsCampaigns: AdsPlanCampaign[];
  createdById?: string;
  submitedById?: string;
};

export type AdsPlanCampaign = {
  id: number;
  planId: number;
  name: string;
  status?: CampaignStatus;
  setupMethod: CampaignSetupMethod;
  platform: AvailablePlatforms;
  platformStatus?: PlatformStatus;
  budget: number;
  currency: Currency;
  periods: CampaignPeriod[];
  objective: Objective;
  metadata: {
    [key: string]: string;
  };
  platformOptions: CampaignPlatformOptions;
  pushedName: string;
  timezone?: string;
  placements: YoutubePlacement[];
  audiences: CampaignAudience[];
  comment?: string;
};

export type CampaignPlatformOptionsMeta = {
  adsetPerInterest?: boolean;
  facebookPageId?: number;
  instagramAccountId?: number;
};

export type CampaignPlatformOptionsYoutube = {
  youtubeAccountId?: number;
};

export type CampaignPlatformOptions =
  | CampaignPlatformOptionsMeta
  | CampaignPlatformOptionsYoutube;

export type CampaignPeriod = {
  datetimeStart: string;
  datetimeEnd: string;
};

export type GetAdsSummaryFilters = {
  releaseTypes?: AvailableReleaseTypes[];
  releaseDates?: AvailableReleaseDates[];
  sanityChecks?: SanityCheckPlanStatus[];
  submittedBy?: string[];
};

export type AdsUserEmployee = {
  id: string;
  businessUnitsIds: number[];
  profileType: AvailableAdsUsersProfileTypes;
  userEmployee: UserEmployee;
};

export type AdsFilterSubmittedUserType = {
  id: string;
  displayName: string;
};

export type AdsFilterReleaseDates = {
  id: string;
  displayName: string;
};

export type AdsObjective = {
  id: string;
  name: string;
  label: string;
  category: Objective;
  business_unit_type: BusinessUnitTypes;
  platform: AvailablePlatforms;
  minimumBudget: number | null;
  maximumBudget: number | null;
  maxAudiences: number | null;
  targetingType: YoutubeTargetingType[];
  availablePlacements: YoutubePlacementType[];
  pricingPlan: string;
  mediabuySupport: boolean;
  launchTime: string;
  maxPlacements: string | number;
  recommendedDurationMin: number;
  recommendedDurationMax: number;
  featureFlag?: string;
};

export enum SORTBY_KEY {
  CAMPAIGN = 'campaign',
  PEROID = 'period',
  BUDGET = 'budget',
}

export enum YoutubeTargetingType {
  SOCIO_DEMOGRAPHICS = 'SOCIO_DEMOGRAPHICS',
  VIDEO_VIEWERS = 'VIDEO_VIEWERS',
  LINKS_VISITORS = 'LINKS_VISITORS',
  SIMILAR_ARTISTS = 'SIMILAR_ARTISTS',
}

export enum YoutubePlacementType {
  YOUTUBE_BUMPER = 'YOUTUBE_BUMPER',
  YOUTUBE_IN_FEED = 'YOUTUBE_IN_FEED',
  YOUTUBE_SKIPPABLE = 'YOUTUBE_SKIPPABLE',
  YOUTUBE_NON_SKIPPABLE = 'YOUTUBE_NON_SKIPPABLE',
}

export type PageContents = {
  tabSource: AdsCampaignSteps;
  defaultContents: DefaultContent;
  leftPanelContent: PanelContent;
};

export type PanelContent = {
  headingLabel: string;
  isLoading: boolean;
  adsCampaigns: AdsPlanCampaign[];
  defaultCardMessage: string;
  removeAction?: (param: CampaignAudience) => void;
};

export type DefaultContent = {
  defaultText: string;
  buttonText: string;
  headingLabel?: string;
};

export enum AdsCampaignSteps {
  STRATEGY = 'strategy',
  AUDIENCE = 'audiences',
  AD_CUSTOMIZATION = 'ads',
}

export type YoutubePlacement = {
  $validationMessages?: string[] | null;
  id: number;
  name: string;
  youtubeUrl?: string;
  finalUrl?: string;
  displayUrl?: string;
  callToAction?: string;
  headline?: string;
  description1?: string;
  description2?: string;
  youtubePremiere?: boolean;
  placement: YoutubePlacementType;
};

export type AdsAudience = {
  targetingType: YoutubeTargetingType;
};

export type CampaignAudience = {
  id: number;
  campaignId: number;
  targetingType: YoutubeTargetingType;
  locations: number[];
  ageRanges: AudienceAgeRanges[];
  similarArtists: string[];
  gender: AudienceGender;
  interests: number[];
};

export type UpdateAudiencePayload = {
  planId: number;
  campaignId: number;
  audienceId: number;
  locations?: number[];
  gender?: AudienceGender;
  ageRanges?: AudienceAgeRanges[];
  similarArtists?: string[];
  interests?: number[];
  targetingType?: YoutubeTargetingType;
};

export type UpdatePlacementPayload = {
  campaignId: number;
  placementId: number;
  name?: string;
  youtubeUrl?: string;
  finalUrl?: string;
  displayUrl?: string;
  callToAction?: string;
  headline?: string;
  description1?: string;
  description2?: string;
};

export enum AudienceGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  ALL = 'ALL',
}

export enum AudienceAgeRanges {
  'UNDETERMINED' = 'UNDETERMINED',
  '18-24' = '18-24',
  '25-34' = '25-34',
  '35-44' = '35-44',
  '45-54' = '45-54',
  '55-64' = '55-64',
  '65+' = '65+',
}

export type YoutubeLocation = {
  id: number;
  name: string;
  canonicalName?: string;
  type?: string;
};

export type YoutubeInterest = {
  id: number;
  name: string;
};

export enum YoutubeSearchKeyType {
  NAME = 'name',
  ID = 'id',
  CANONICAL_NAME = 'canonicalName',
  TYPE = 'type',
}

export type BusinessUnits = {
  id: number;
  name: string;
};

export enum PricingPlans {
  ACCESS = 'Access',
  ADVANCED = 'Advanced',
  PREMIUM = 'Premium',
}

export type CampaignWarningData = {
  [campaignId: string]: {
    isAudienceExceeds: boolean;
    maxAllowedAudiences: number;
    nonEligibleAudienceIds: number[];
    isPlacementExceeds: boolean;
    maxAllowedPlacements: number;
  };
};

export type ValidationMessages = {
  [key: string]: [string];
};

export type FormFieldsErrors = {
  [key: string]: boolean;
};

export type ValidationError = {
  [key: string]: boolean;
};

export type ValidationData = {
  [placementId: number]: ValidationError;
};

export type defaultPageProps = {
  step: AdsCampaignSteps;
  title: string;
  nextButtonLabel: string;
  nextButtonAction: () => void;
  defaultTitle: string;
  defaultButtonLabel: string;
  defaultText: string;
  onDefaultPageButtonClick: () => void;
  bgColor: string;
  children?: React.ReactNode;
};
