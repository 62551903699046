import React, { useState, useEffect } from 'react';

import { Action } from 'shared/entities/action/action.types';
import {
  fetchBacklinkAction,
  createBacklinkAction,
  updateBacklinkAction,
} from 'shared/entities/backlink/backlink.api';

import ActionCard from './ActionCard';
import EditionDialog from './EditionDialog';

type Props = {
  actionId?: string;
  backlinkId?: string;
  onActionCreated?: (action: Action) => void;
  onActionUpdated?: (action: Action) => void;
  onActionRemoved?: () => void;
  onCancel?: () => void;
  isDefaultAction?: boolean;
  defaultTimezone: string;
};

const ActionEditor = ({
  actionId,
  backlinkId,
  onActionCreated,
  onActionUpdated,
  onActionRemoved,
  onCancel,
  isDefaultAction,
  defaultTimezone,
}: Props) => {
  const [action, setAction] = useState<Action>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      if (!actionId || !backlinkId) return;
      try {
        const response = await fetchBacklinkAction({ backlinkId, actionId });
        setAction(response);
      } catch (error: any) {
        console.error(error);
      }
    };
    fetch();
  }, [actionId, backlinkId]);

  const handleCreateAction = async (action: Action) => {
    if (backlinkId) {
      const response = await createBacklinkAction({
        backlinkId,
        action,
      });
      onActionCreated && onActionCreated(response);
    }
  };

  const handleUpdateAction = async (action: Action) => {
    if (backlinkId && actionId) {
      const response = await updateBacklinkAction({
        backlinkId,
        actionId,
        action,
      });
      setAction(response);
      setIsEditing(false);
      onActionUpdated && onActionUpdated(response);
    }
  };

  const handleRemoveAction = async () => {
    onActionRemoved && onActionRemoved();
  };

  return (
    <div>
      {actionId && (
        <ActionCard
          onEditAction={() => setIsEditing(true)}
          onRemoveAction={handleRemoveAction}
          action={action}
          isEditing={isEditing}
          isDefaultAction={!!isDefaultAction}
        />
      )}
      {(isEditing || !actionId) && (
        <EditionDialog
          isOpen={true}
          action={action}
          onCreateAction={handleCreateAction}
          onUpdateAction={handleUpdateAction}
          onEditionCanceled={() => {
            setIsEditing(false);
            onCancel && onCancel();
          }}
          defaultTimezone={defaultTimezone}
        />
      )}
    </div>
  );
};

export default ActionEditor;
