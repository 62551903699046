import React from 'react';

import { Global, css, Interpolation } from '@emotion/react';

import { styled, Button, ButtonBase, Theme as MuiTheme } from '@mui/material';

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
  export interface GlobalProps {
    styles: Interpolation<Theme>;
  }
}

export const GlobalStyles = () => (
  <Global
    styles={(theme) => css`
      body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: red;
      }
    `}
  />
);

export const ButtonDanger = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;
export const ButtonBaseDanger = styled(ButtonBase)`
  && {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

type SpaceProps = {
  bottom?: number;
  top?: number;
  left?: number;
  right?: number;
};
// NOTE: use material ui Box component instead, except if you need the "!important"
export const Space = styled('div')<SpaceProps>`
  ${({ theme, bottom, top, left, right }) => `
    ${!!bottom ? `margin-bottom: ${theme.spacing(bottom)} !important;` : ''}
    ${!!top ? `margin-top: ${theme.spacing(top)} !important;` : ''}
    ${!!left ? `margin-left: ${theme.spacing(left)} !important;` : ''}
    ${!!right ? `margin-right: ${theme.spacing(right)} !important;` : ''}
  `}
`;
